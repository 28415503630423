import { InputHTMLAttributes, useRef } from 'react';
import { LabelText, Small } from '../../components/Text';
import * as styles from './Input.styles';
import { useToggle } from 'react-use';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  label: string;
  sublabel?: React.ReactNode;
}

export default function Input({
  id,
  label,
  value,
  sublabel,
  onBlur = () => {},
  type,
  ...rest
}: InputProps) {
  const [focused, toggleFocus] = useToggle(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = (e) => {
    toggleFocus();
    onBlur(e);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <styles.root>
      {type === 'checkbox' ?
        <div style={{ marginTop: '10px' }}>
          <LabelText
            as="label"
            color="black80"
            paddingBottom={sublabel ? 1 : 2}
            onClick={handleFocus}
          >
            <input
              value={value}
              type="checkbox"
              style={{ marginRight: '10px' }}
              ref={inputRef}
              onFocus={toggleFocus}
              onBlur={handleBlur}
              {...rest} />
            {label}
          </LabelText>
        </div> :
        <styles.inputWrap focused={focused}>
          <LabelText
            as="label"
            color="black80"
            htmlFor={id}
            paddingBottom={sublabel ? 1 : 2}
            onClick={handleFocus}
          >
            {label}
          </LabelText>
          {sublabel && (
            <Small fontWeight={400} fontStyle="italic" color="gray100" pb={1}>
              {sublabel}
            </Small>
          )}
          <styles.input
            value={value}
            id={id}
            ref={inputRef}
            onFocus={toggleFocus}
            onBlur={handleBlur}
            type={type}
            {...rest}
          />
        </styles.inputWrap>
      }
    </styles.root>
  );
}
