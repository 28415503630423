import { gql } from '@apollo/client';

export const GET_DASHBOARD_DATA_QUERY = gql`
  query GetMunicipalityData(
    $where: MunicipalityWhereUniqueInput!
    $carbonFootprintWhere: EntityCarbonFootprintByYearWhereInput!
  ) {
    municipality(where: $where) {
      id
      entities {
        id
        name
        carbonFootprintByYear(where: $carbonFootprintWhere) {
          id
          year
          completed
          drivingData {
            ownedVehicles {
              gasoline
              diesel
              metan
              electricity
            }
            rentalVehicles {
              gasoline
              diesel
              metan
              electricity
            }
            taxi {
              gasoline
              diesel
              metan
              electricity
            }
            machineryAndEquipment {
              gasoline
              diesel
              metan
              electricity
              kerosene
            }
          }
          energy {
            electricity
            hotWater
          }
          flights {
            icao {
              total
            }
            domesticFlights {
              AEYtoRVKtoAEY
              EGStoRVKtoEGS
              HFNtoRVKtoHFN
              IFJtoRVKtoIFJ
              VEYtoRVKtoVEY
              other
            }
            internationalFlights {
              oceania
              continentalEurope
              scandinavia
              northAmerica
              southAmerica
              africa
              asia
            }
          }
          waste {
            landfill
            composting
            incineration
            plastic
            paper
            glass
            metals
            bulky
          }
          carbonOffset {
            quantity
          }
        }
      }
    }
  }
`;
