import { H2 } from '../../components/Text';
import { Box } from '../../styles/Box';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_carbonOffset } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { GridContainer, GridItem } from '../../styles/Grid';
import FieldExplanation from '../../components/Text/FieldExplanation';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';

type HandleChange<T> = <OnChange extends UpdateFunction>(
  onChange: OnChange,
) => <Type extends keyof T, Value extends T[Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_carbonOffset> =
  (onChange) => {
    return (type, value) => {
      return onChange((oldState) => {
        const newState = { ...oldState };
        newState.carbonOffset[type] = value;
        return newState;
      });
    };
  };

const CarbonOffset: ModalDataComponent = ({ state, onChange }) => {
  const {
    carbonOffset: { quantity, comment },
  } = state;

  const handleChange = createChangeHandler(onChange);

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Kolefnisjöfnun</H2>
        <FieldExplanation>
          Kolefnisjöfnun felur í sér að kaupa kolefnisjöfnunareiningar frá öðrum
          aðila. <strong>Ein kolefnisjöfnunareining</strong> samsvarar{' '}
          <strong>einu tonni</strong> af gróðurhúsalofttegundum.
        </FieldExplanation>
        <FieldExplanation>
          Sjá frekari leiðbeiningar um kolefnisjöfnun fyrir opinbera aðila á
          vefsíðu þessa verkefnis, <em>Loftslagsvænni sveitarfélög</em>, eða á
          vefsíðu Umhverfisstofnunar. Fyrir frekari upplýsingar um
          kolefnisjöfnun má sjá á{' '}
          <a
            href="https://ust.is/graent-samfelag/graenn-lifstill/kolefnisjofnun/"
            target="_blank"
            rel="noreferrer"
          >
            vefsíðu Umhverfisstofnunar
          </a>
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="carbonOffset.quantity"
            label="Fjöldi kolefnisjöfnunareininga"
            value={quantity}
            placeholder="0"
            onValueChange={(e) => handleChange('quantity', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <TextArea
            id="carbonOffset.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export default CarbonOffset;
