import { useRef } from 'react';
import { LabelText, Small } from '../../components/Text';
import * as styles from './Input.styles';
import { useToggle } from 'react-use';
import { NumericFormat, NumericFormatProps } from 'react-number-format';

// Omit onChange because it interfeers with onValueChange
interface NumberFormatInputProps
  extends Omit<NumericFormatProps, 'onChange'> {
  label: string;
  sublabel?: React.ReactNode;
}

export default function NumberFormatInput({
  id,
  label,
  value,
  sublabel,
  onBlur = () => {},
  type,
  placeholder,
  required,
  onValueChange,
}: NumberFormatInputProps) {
  const [focused, toggleFocus] = useToggle(false);
  const inputRef = useRef<HTMLInputElement>(null);

  const handleBlur = (e) => {
    toggleFocus();
    onBlur(e);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <styles.root>
      <styles.inputWrap focused={focused}>
        <LabelText
          as="label"
          color="black80"
          htmlFor={id}
          paddingBottom={sublabel ? 1 : 2}
          onClick={handleFocus}
        >
          {label}
        </LabelText>
        {sublabel && (
          <Small fontWeight={400} fontStyle="italic" color="gray100" pb={1}>
            {sublabel}
          </Small>
        )}
        <NumericFormat
          value={value}
          id={id}
          getInputRef={inputRef}
          onFocus={toggleFocus}
          onBlur={handleBlur}
          inputMode="decimal"
          thousandSeparator="."
          decimalSeparator=","
          placeholder={placeholder}
          required={required}
          onValueChange={onValueChange}
          customInput={styles.input}
        />
      </styles.inputWrap>
    </styles.root>
  );
}
