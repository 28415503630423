import theme from '../../utils/theme';
import { Box } from '../Box';

const defaultGridTemplateColumns = {
  md: 'repeat(2, 1fr)',
  lg: 'repeat(4, 1fr)',
};

export const GridContainer = (props) => (
  <Box
    $display="grid"
    $gridRowGap={props.rowGap || theme.grid.gap}
    $gridColumnGap={props.columnGap || theme.grid.gap}
    $gridTemplateColumns={
      props.$gridTemplateColumns || defaultGridTemplateColumns
    }
    $gridTemplateRows={props.$gridTemplateRows}
    {...props}
  />
);

export const GridItem = Box;
