// To prevent 'styled-components' from leaking props to the DOM we can prefix props with '$'
// This utility removes that prefix so it will work with 'styled-system'.

export type Transient<T> = {
  [K in keyof T as `$${string & K}`]: T[K];
};

export const transient = (parse) => (props) => {
  const unprefixedProps = Object.entries(props).reduce((acc, [key, value]) => {
    let unprefixedKey = key;

    if (key.startsWith("$")) {
      unprefixedKey = key.substring(1);
    }

    acc[unprefixedKey] = value;

    return acc;
  }, {});
  return parse(unprefixedProps);
};
