import styled from 'styled-components';
import {
  space,
  SpaceProps,
  LayoutProps,
  variant,
  ButtonStyleProps,
} from 'styled-system';
import theme from '../../utils/theme';
import { layout } from '../../styles/layout';
import { media } from '../../utils/media';
import { transient, Transient } from '../../utils/transient';

export type ButtonVariants =
  | 'primary'
  | 'secondary'
  | 'callToActionLight'
  | 'callToActionDark'
  | 'pill';

export type ButtonColorSchemes = 'dark' | 'light';

type ButtonProps = Transient<SpaceProps> &
  Transient<LayoutProps> &
  ButtonStyleProps & {
    colorScheme?: ButtonColorSchemes;
    alignment?: 'start' | 'end';
  };

const callToActionBase = {
  borderRadius: 'button',
  paddingX: 6,
  paddingY: 2,
};

export const Button = styled('button')<ButtonProps>(
  ({ color = 'currentColor', variant, alignment }) => ({
    display: 'inline-flex',
    textAlign: 'center',
    alignItems: 'center',
    justifyContent: 'center',
    borderStyle: 'solid',
    ...(alignment === 'end'
      ? {
          alignSelf: 'flex-end',
          marginLeft: 'auto',
        }
      : {}),
    ...(variant === 'pill'
      ? {
          color: theme.colors[`${color}100`],
          backgroundColor: theme.colors[`${color}20`],
        }
      : {
          color: theme.colors[color] || color,
        }),
    borderRadius: theme.borderRadius.small,
    cursor: 'pointer',
    '> *::selection': {
      background: 'transparent',
    },
  }),
  transient(space),
  transient(layout),
  variant({
    variants: {
      primary: {
        paddingY: 2,
        paddingX: 3,
        display: 'flex',
        backgroundColor: 'darkBlue',
        borderWidth: 1,
        borderRadius: 'huge',
        minWidth: 160,
        color: 'white',
        [media['md']]: {
          ':hover': {
            backgroundColor: 'blue100',
          },
        },
      },
      secondary: {
        paddingY: 2,
        paddingX: 3,
        backgroundColor: 'gray10',
        borderWidth: 1,
        borderRadius: 'large',
        borderColor: 'transparent',
        [media['md']]: {
          ':hover': {
            backgroundColor: 'gray5',
            borderColor: 'gray10',
          },
        },
      },
      pill: {
        paddingY: 1,
        paddingX: 1.5,
        minWidth: 100,
        borderWidth: 1,
        opacity: 0.85,
        borderRadius: 'large',
        borderColor: 'transparent',
        [media['md']]: {
          ':hover': {
            opacity: 1,
          },
        },
      },
      callToActionLight: {
        backgroundColor: 'white',
        color: 'purple',
        ...callToActionBase,
        [media['md']]: {
          ':hover': {
            color: 'purple50',
          },
        },
      },
      callToActionDark: {
        backgroundColor: 'purple',
        color: 'white',
        ...callToActionBase,
        [media['md']]: {
          ':hover': {
            backgroundColor: 'purple50',
          },
        },
      },
    },
  }),
);
