import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import { Main } from './components/Layout';
import Sidebar from './components/Sidebar';
import * as styles from './App.styles';
import Dashboard from './screens/Dashboard';
import Establishment from './screens/Establishment';
import Settings from './screens/Settings';
import Coefficients from './screens/Coefficients';
import UserProvider, { useUser } from './context/user';
import VerifyDocuments from './screens/VerifyDocuments';

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.
function PrivateRoute({ children, ...rest }) {
  let { municipality } = useUser();
  return (
    <Route
      {...rest}
      render={({ location }) =>
        municipality ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/auth',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

function App() {
  return (
    <UserProvider>
      <div className="App">
        <Router>
          <Switch>
            <styles.root>
              <styles.content>
                <Sidebar />
                <Main>
                  <Redirect from="/" to="/yfirlit" />
                  <PrivateRoute exact path="/yfirlit">
                    <Dashboard />
                  </PrivateRoute>
                  <PrivateRoute exact path="/rekstrareiningar">
                    <Establishment />
                  </PrivateRoute>
                  <PrivateRoute exact path="/stillingar">
                    <Settings />
                  </PrivateRoute>
                  <PrivateRoute exact path="/breytur">
                    <Coefficients />
                  </PrivateRoute>
                  <PrivateRoute exact path="/skil">
                    <VerifyDocuments />
                  </PrivateRoute>
                </Main>
              </styles.content>
            </styles.root>
          </Switch>
        </Router>
      </div>
    </UserProvider>
  );
}
export default App;
