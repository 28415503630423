import { gql, useMutation, useQuery } from '@apollo/client';
import React from 'react';
import { DialogStateReturn } from 'reakit/ts';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import { layout } from '../components/Modal';
import { H2, H3 } from '../components/Text';
import { GetEntitiesInMunicipality_municipality } from '../queries/__generated__/GetEntitiesInMunicipality';
import { Box } from '../styles/Box';
import {
  EntityQuery,
  EntityQueryVariables,
  EntityQuery_entity,
} from './__generated__/EntityQuery';
import {
  UpsertEntityMutation,
  UpsertEntityMutationVariables,
} from './__generated__/UpsertEntityMutation';

const ENTITY_FRAGMENT = gql`
  fragment EntityModalFragment on Entity {
    id
    name
  }
`;

const ENTITY_QUERY = gql`
  ${ENTITY_FRAGMENT}
  query EntityQuery($where: EntityWhereUniqueInput!) {
    entity(where: $where) {
      ...EntityModalFragment
    }
  }
`;

const UPSERT_ENTITY_MUTATION = gql`
  ${ENTITY_FRAGMENT}
  mutation UpsertEntityMutation(
    $create: EntityCreateInput!
    $update: EntityUpdateInput!
    $where: EntityWhereUniqueInput!
  ) {
    upsertOneEntity(create: $create, update: $update, where: $where) {
      ...EntityModalFragment
    }
  }
`;

type DataModalProps = {
  modal: DialogStateReturn;
  municipality: GetEntitiesInMunicipality_municipality;
  entityId?: EntityQueryVariables['where']['id'];
  onCreateNewEntity?: () => void;
};

export function EntityModal({
  modal,
  entityId,
  municipality,
  onCreateNewEntity,
}: DataModalProps) {
  const { data } = useQuery<EntityQuery, EntityQueryVariables>(ENTITY_QUERY, {
    skip: typeof entityId === 'undefined',
    variables: { where: { id: entityId } },
  });

  const [commit] = useMutation<
    UpsertEntityMutation,
    UpsertEntityMutationVariables
  >(UPSERT_ENTITY_MUTATION);

  const [state, setState] = React.useState<Partial<EntityQuery_entity>>({});

  React.useEffect(() => setState(data?.entity || {}), [data]);

  const hideModal = () => {
    modal.hide();
    setState({ name: '' });
  }

  const submitData = React.useCallback(
    () =>
      commit({
        variables: {
          create: {
            name: state.name,
            municipality: { connect: { id: municipality.id } },
          },
          update: {
            name: { set: state.name },
          },
          where: { id: data?.entity.id || -1 },
        },
      }),
    [commit, state.name, municipality.id, data?.entity.id],
  );

  return (
    <layout.modalOuter>
      <layout.inner>
        <layout.content>
          <layout.scrollable>
            <div>
              <Box $paddingTop={4} $paddingBottom={1}>
                <H2>
                  {data?.entity?.name ? data.entity.name : 'Skrá nýja rekstrareiningu'}
                </H2>
                <H3 paddingY={2} color="black80" fontWeight={400}>
                  {data?.entity?.name
                    ? 'Breyta skráningu hjá ' + data.entity.name
                    : 'Skrá nýja rekstrareiningu hjá ' + municipality.name}
                </H3>
              </Box>

              <Box $paddingY={1}>
                <Input
                  id="entity.name"
                  type="string"
                  label="Nafn rekstrareiningu"
                  value={state.name}
                  onChange={(e) => setState({ name: e.target.value })}
                />
              </Box>
            </div>
          </layout.scrollable>
          <layout.bottom>
            <Box $paddingRight={2}>
              <Button variant="secondary" onClick={() => hideModal()}>
                Hætta við
              </Button>
            </Box>
            <Box $paddingLeft={2}>
              <Button
                variant="primary"
                onClick={async () => {
                  await submitData();
                  if (onCreateNewEntity) onCreateNewEntity();
                  hideModal();
                }}
              >
                Vista
              </Button>
            </Box>
          </layout.bottom>
        </layout.content>
      </layout.inner>
    </layout.modalOuter>
  );
}
