import { gql, useQuery } from '@apollo/client';
import { ReactNode } from 'react';
import { NavLink } from 'react-router-dom';

import Icon from '../Icon';
import { IconProps } from '../Icon/Icon';
import { Text } from '../Text';
import * as styles from './Sidebar.styles';
import { UserRole } from './__generated__/UserRole';

function Link({
  icon,
  children,
  to,
}: {
  isActive?: boolean;
  icon: IconProps['name'];
  children: ReactNode;
  to: string;
}) {
  return (
    <styles.link to={to}>
      <Icon name={icon} />
      <Text fontWeight={600} pl={2}>
        {children}
      </Text>
    </styles.link>
  );
}

const GET_USER_ROLE_QUERY = gql`
  query UserRole {
    me {
      role
    }
  }
`;

export default function Sidebar() {
  const { data } = useQuery<UserRole>(GET_USER_ROLE_QUERY);

  const role = data?.me?.role;

  return (
    <styles.root>
      <NavLink to="/yfirlit">
        <styles.Logo src="/logo.svg" alt="Loftslagsvænni sveitarfélög" />
      </NavLink>
      <nav>
        <styles.list>
          <Link icon="dashboard" to="/yfirlit">
            Yfirlit
          </Link>
          <Link icon="menu-boxed" to="/rekstrareiningar">
            Rekstrareiningar
          </Link>
          <Link icon="setting" to="/stillingar">
            Stillingar
          </Link>
          {role === 'ADMIN' ? (
            <>
              <Link icon="view-list" to="/breytur">
                Breytur
              </Link>
              <Link icon="checklist" to="/skil">
                Skilað
              </Link>
            </>
          ) : null}
        </styles.list>
      </nav>
    </styles.root>
  );
}
