import theme from '../utils/theme';

export const media = Object.keys(theme.breakpoints).reduce(
  (acc: Record<string, any>, label) => {
    acc[label] = `
      @media (min-width: ${theme.breakpoints[label]})
    `;

    return acc;
  },
  {},
);
