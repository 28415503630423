import { useMemo, lazy } from 'react';

export interface IconProps {
  height?: number | string;
  width?: number | string;
  color?: string;
  name:
    | 'dashboard'
    | 'view-list'
    | 'calendar'
    | 'arrow-up'
    | 'arrow-down'
    | 'download'
    | 'comment'
    | 'setting'
    | 'menu-boxed'
    | 'caret-down'
    | 'plus'
    | 'edit'
    | 'driving'
    | 'electricity'
    | 'flight'
    | 'forest'
    | 'trash'
    | 'checklist'
}

function getIconByName(name: IconProps['name']) {
  switch (name) {
    case 'dashboard': {
      return lazy(() => import('./icons/Dashboard'));
    }
    case 'view-list': {
      return lazy(() => import('./icons/ViewList'));
    }
    case 'calendar': {
      return lazy(() => import('./icons/Calendar'));
    }
    case 'arrow-up': {
      return lazy(() => import('./icons/ArrowUp'));
    }
    case 'arrow-down': {
      return lazy(() => import('./icons/ArrowDown'));
    }
    case 'download': {
      return lazy(() => import('./icons/Download'));
    }
    case 'comment': {
      return lazy(() => import('./icons/Comment'));
    }
    case 'setting': {
      return lazy(() => import('./icons/Setting'));
    }
    case 'menu-boxed': {
      return lazy(() => import('./icons/MenuBoxed'));
    }
    case 'caret-down': {
      return lazy(() => import('./icons/CaretDown'));
    }
    case 'plus': {
      return lazy(() => import('./icons/Plus'));
    }
    case 'edit': {
      return lazy(() => import('./icons/Edit'));
    }
    case 'driving': {
      return lazy(() => import('./icons/Driving'));
    }
    case 'electricity': {
      return lazy(() => import('./icons/Electricity'));
    }
    case 'flight': {
      return lazy(() => import('./icons/Flight'));
    }
    case 'forest': {
      return lazy(() => import('./icons/Forest'));
    }
    case 'trash': {
      return lazy(() => import('./icons/Trash'));
    }
    case 'checklist': {
      return lazy(() => import('./icons/Checklist'));
    }
  }
}

type $FixMe = any;

function Icon({ name, ...rest }: IconProps) {
  const Component = useMemo(() => getIconByName(name), [name]);
  return <Component {...(rest as $FixMe)} />;
}

export default Icon;
