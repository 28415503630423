import { useStore } from '../../../state';
import { Box } from '../../../styles/Box';
import Icon from '../../Icon';
import { Text, Title } from '../../Text';
import * as styles from './ModalSidebar.styles';

type ModalSidebarProps = {
  selectedYear: number;
  activeEstablishmentName: string;
  children: JSX.Element;
};

export function ModalSidebar({
  selectedYear,
  activeEstablishmentName,
  children,
}: ModalSidebarProps) {
  const activeCategory = useStore((state) => state.activeCategory);
  const activeItemKey = useStore((state) => state.activeItem);
  const categories = useStore((state) => state.categories);
  const { setActiveCategory } = useStore((state) => state.actions);
  const { items } = categories.find((cat) => cat.key === activeCategory);

  return (
    <styles.sidebar>
      <Box $pb={2} $pt={1.5}>
        <Text fontSize={{ md: 18 }} fontWeight={500}>
          {activeEstablishmentName}
        </Text>
        <Text fontSize={{ md: 16 }} py={1}>
          {selectedYear}
        </Text>
        <styles.list>
          {categories.map((category) => {
            const isActive = activeCategory === category.key;
            return (
              <styles.listItem key={category.key}>
                <styles.category active={isActive}>
                  <Box $marginRight={1} $display="flex">
                    <Icon name={category.iconName} height={18} width={18} />
                  </Box>
                  <Title
                    as="button"
                    onClick={() => setActiveCategory(category.key)}
                    p={1}
                  >
                    {category.title}
                  </Title>
                  {/*<styles.statusIcon status={category.status} /> */}
                </styles.category>
                {isActive &&
                  items.length > 1 &&
                  items.map((item) => (
                    <styles.categoryItem
                      key={item.title}
                      active={item.key === activeItemKey}
                      error={item.error}
                    >
                      <Title
                        as="button"
                        onClick={() =>
                          useStore.setState({ activeItem: item.key })
                        }
                        p={1}
                        fontWeight={500}
                      >
                        {item.title}
                      </Title>
                    </styles.categoryItem>
                  ))}
              </styles.listItem>
            );
          })}
        </styles.list>
      </Box>
      {children}
    </styles.sidebar>
  );
}
