import { useMemo } from 'react';
import {
  Cell,
  Legend,
  Pie,
  PieChart,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { Payload } from 'recharts/types/component/DefaultLegendContent';
import styled from 'styled-components';
import Table from '../components/Table';
import { formatNumber } from '../utils/formatting';

const SquareWrap = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

const Square = styled('span')<{ squareColor?: string }>(
  ({ squareColor = 'transparent' }) => ({
    display: 'block',
    width: 12,
    height: 12,
    marginRight: 12,
    background: squareColor,
  }),
);

type DataType = { name: string; value: number; color: string }[];

const renderLegendContent = (
  legendPayload: Payload[],
  mainCategoryName: string,
  dataByCategory: DataType,
) => {
  let categories = [];

  if (Array.isArray(dataByCategory)) {
    // If we are rendering `dataByCategory` we want to skip rendering
    // legends that belong to subcategories.
    // Subcategories are the difference between `payload` and `dataByCategory`.

    categories = legendPayload.filter((entry) =>
      dataByCategory.map((d) => d.name).includes(entry.value),
    );
  } else {
    categories = legendPayload;
  }

  return (
    <Table>
      <thead>
        <tr>
          <th>{mainCategoryName}</th>
          <th>Losun</th>
          <th>% af heild</th>
        </tr>
      </thead>
      <tbody>
        {categories.map((entry) => {
          const { value: category, color, payload } = entry;
          const { percent, value } = payload;
          return (
            <tr key={category}>
              <td>
                <SquareWrap>
                  <Square squareColor={color} />
                  {category}
                </SquareWrap>
              </td>
              <td>{formatNumber(value / 1000, 1)} tonn CO₂</td>
              <td>{percent ? formatNumber(percent * 100, 2) : 0}%</td>
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};

const OverviewPieChart = ({
  data,
  dataByCategory,
  showLegend = false,
  mainCategoryName = 'Flokkur',
}: {
  data: DataType;
  dataByCategory?: DataType;
  showLegend?: boolean;
  mainCategoryName?: string;
}) => {
  const renderLegend = useMemo(
    () => (
      <Legend
        content={({ payload }) =>
          renderLegendContent(payload, mainCategoryName, dataByCategory)
        }
        wrapperStyle={{
          position: 'absolute',
          width: '50%',
          display: 'flex',
          justifyContent: 'center',
          maxHeight: '100%',
          overflow: 'auto',
        }}
        layout="vertical"
        verticalAlign="middle"
        align="right"
      />
    ),
    [dataByCategory, mainCategoryName],
  );

  return (
    <ResponsiveContainer width="100%" height={400}>
      <PieChart>
        <Pie
          data={data}
          dataKey="value"
          cx="50%"
          cy="50%"
          outerRadius={dataByCategory ? '60%' : '90%'}
          fill="#8884d8"
        >
          {data.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
        </Pie>
        {dataByCategory && (
          <Pie
            data={dataByCategory}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={'70%'}
            outerRadius={'90%'}
            fill="#82ca9d"
          >
            {dataByCategory.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
        )}

        {showLegend && renderLegend}
        <Tooltip formatter={(value) => formatNumber(value) + ' kg CO₂'} />
      </PieChart>
    </ResponsiveContainer>
  );
};

export default OverviewPieChart;
