import styled from 'styled-components';
import {
  space,
  color,
  ColorProps,
  fontSize,
  FontSizeProps,
  fontWeight,
  FontWeightProps,
  fontFamily,
  FontFamilyProps,
  SpaceProps,
  typography,
  TypographyProps,
} from 'styled-system';
import { transient } from '../../utils/transient';

export type BaseTextProps = FontSizeProps &
  FontWeightProps &
  FontFamilyProps &
  SpaceProps &
  ColorProps &
  TypographyProps & {
    id?: string;
    capitalize?: boolean;
    uppercase?: boolean;
    verticalAlign?: 'middle';
    whiteSpace?: 'nowrap' | 'normal';
    hyphens?: boolean;
  };

export const BaseText = styled('p')<BaseTextProps>(
  transient(fontSize),
  transient(typography),
  transient(fontWeight),
  transient(fontFamily),
  transient(space),
  transient(color),
  {
    display: 'block',
    appearance: 'none',
    border: 0,
    background: 'none',
    WebkitFontSmoothing: 'antialiased',
    strong: {
      fontWeight: 600,
    },
    em: {
      fontStyle: 'italic',
    },
    a: {
      color: 'blue',
      textDecoration: 'underline',
    },
  },
  ({ capitalize }) =>
    capitalize && {
      textTransform: 'capitalize',
    },
  ({ uppercase }) =>
    uppercase && {
      textTransform: 'uppercase',
    },
  ({ whiteSpace }) =>
    whiteSpace && {
      whiteSpace,
    },
  ({ verticalAlign }) => ({
    verticalAlign,
  }),
  ({ hyphens }) =>
    hyphens && {
      hyphens: 'auto',
    },
  ({ onClick }) =>
    typeof onClick === 'function' && {
      cursor: 'pointer',
    },
);
