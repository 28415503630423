import { ReactNode } from 'react';
import { Text } from './Text';
import { Box } from '../../styles/Box';
import { colors } from '../../utils/theme';

export default function FieldExplanation({
  children,
  borderColor,
}: {
  children: ReactNode;
  borderColor?: keyof typeof colors;
}) {
  return (
    <Box
      $borderLeft="2px solid"
      $borderLeftColor={colors[borderColor] || colors.blue60}
      $padding="0 16px"
      $margin="16px 0"
    >
      <Text color="black80" fontWeight={400}>
        {children}
      </Text>
    </Box>
  );
}
