import styled from 'styled-components';
import { media } from '../../utils/media';
import theme from '../../utils/theme';

export const root = styled('div')({
  padding: theme.space[3],
  background: theme.colors.gray5,
  borderRadius: '16px 0 0 16px',
});

export const content = styled('div')<{ columns: number }>(({ columns }) => ({
  display: 'grid',
  gap: theme.grid.gap,
  flex: 1,
  [media['md']]: {
    gridTemplateColumns: `repeat(${columns / 2}, 1fr)`,
  },
  [media['lg']]: {
    gridTemplateColumns: `repeat(${columns}, 1fr)`,
  }
}));
