import styled from 'styled-components';
import { CategoryStatus } from '../../../types';
import { constants } from '../../../utils/constants';
import theme from '../../../utils/theme';

export const sidebar = styled('aside')({
  backgroundColor: theme.colors.gray5,
  borderRadius: theme.borderRadius.card,
  display: 'flex',
  padding: theme.space[4],
  flexGrow: 1,
  flexShrink: 0,
  flexBasis: constants.SIDEBAR_WIDTH,
  flexDirection: 'column',
  justifyContent: 'space-between',
  button: {
    textAlign: 'left',
    lineHeight: 1.5,
    width: '100%',
  },
  overflow: 'auto',
});

export const list = styled('ul')({});

export const listItem = styled('li')({
  display: 'block',
});

export const category = styled('span')<{ active?: boolean }>(({ active }) => ({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.space[1],
  paddingTop: theme.space[1],
  color: active ? theme.colors.blue : theme.colors.gray100,
  button: {
    color: active ? theme.colors.blue : theme.colors.black80,
  },
}));

export const categoryItem = styled('span')<{
  active?: boolean;
  error?: boolean;
}>(({ active, error }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.space[1],
  marginTop: theme.space[1],
  color: active ? theme.colors.blue : theme.colors.black80,
  marginLeft: theme.space[4],
  position: 'relative',
  '&::after': {
    background: theme.colors.red,
    borderRadius: '50%',
    content: error ? '""' : null,
    height: 4,
    width: 4,
  },
}));

const colorFromStatus = {
  ok: theme.colors.green100,
  'in-progress': theme.colors.yellow100,
  error: theme.colors.red100,
};

const titleFromStatus = {
  ok: 'Í lagi',
  'in-progress': 'Í vinnslu',
  error: 'Villa',
};

export const statusIcon = styled('span').attrs(
  ({ status }: { status: CategoryStatus }) => ({
    title: titleFromStatus[status],
  }),
)<{ status: CategoryStatus }>(({ status }) => ({
  background: colorFromStatus[status],
  borderRadius: '50%',
  flex: 'none',
  height: 8,
  width: 8,
}));
