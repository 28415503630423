import { ReactNode } from 'react';
import { H3 } from '../Text';
import * as styles from './Section.styles';

type SectionProps = {
  children: ReactNode;
  heading?: string;
  columns?: number;
};

export default function Section({ children, heading, columns = 4 }: SectionProps) {
  return (
    <styles.root>
      {heading && <H3 pb={3}>{heading}</H3>}
      <styles.content columns={columns}>{children}</styles.content>
    </styles.root>
  );
}
