import { layout as systemLayout, system, compose, Config } from 'styled-system';

const config: Config = {
  width: {
    property: 'width',
    transform: value => {
      if (value === 'full') {
        return '100%';
      }
      return value;
    },
  },
  height: {
    property: 'height',
    transform: value => {
      if (value === 'full') {
        return '100%';
      }
      return value;
    },
  },
};

export const layout = compose(systemLayout, system(config));
