const UNIT = 8;

type Spacing = Record<string, any>;

const createSpacing = () => {
  let obj = {} as Spacing;
  const smallest = -32;
  const largest = 32;
  const step = 0.5;

  for (let val = smallest; val <= largest; val += step) {
    obj[val] = val * UNIT;
  }

  return obj;
};

export const breakpointValues = [0, 376, 768, 1024, 1440];

const createBreakpoints = () => {
  // 'styled-system' expects an array of breakpoints
  const bps = breakpointValues.map((bp) => `${bp}px`) as Record<string, any>;

  // We want to use keys so we alias the values
  bps.xs = bps[0];
  bps.sm = bps[1];
  bps.md = bps[2];
  bps.lg = bps[3];
  bps.xl = bps[4];

  // bps is now an object ¯\_(ツ)_/¯
  return bps;
};

const grid = {
  gap: '24px',
};

export const colors = {
  white: '#fff',

  black: '#171717',
  black100: '#171717',
  black80: '#454545',
  black60: '#747474',
  black40: '#A2A2A2',

  gray: '#8F92A1',
  gray100: '#8F92A1',
  gray80: '#A5A8B4',
  gray60: '#BCBEC7',
  gray40: '#D2D3D9',
  gray20: '#E9E9EC',
  gray10: '#F4F5F6',
  gray5: '#F9FAFA',

  lightGray: '#F3F6F8',
  lightGray100: '#F3F6F8',
  lightGray80: '#F5F8F9',
  lightGray60: '#F8FAFB',
  lightGray40: '#FAFBFC',

  blue: '#0052CC',
  blue100: '#0052CC',
  blue80: '#3375D6',
  blue60: '#6697E0',
  blue40: '#99BAEB',
  blue20: '#CCDCF5',
  blue5: '#F2F6FC',

  darkBlue: '#1D355A',

  pink: '#F8B6C3',
  pink100: '#F8B6C3',
  pink80: '#F9C5CF',
  pink60: '#FBD3DB',
  pink40: '#FCE2E7',

  yellow: '#FF991F',
  yellow100: '#FF991F',
  yellow80: '#FFAD4C',
  yelow60: '#FFC279',
  yellow40: '#FFD6A5',
  yellow20: '#FFEBD2',

  purple: '#5243AA',
  purple100: '#5243AA',
  purple80: '#7569BB',
  purple60: '#978ECC',
  purple40: '#BAB4DD',

  green: '#00875A',
  green100: '#00875A',
  green80: '#339F7B',
  green60: '#66B79C',
  green40: '#99CFBD',
  green20: '#CCE7DE',

  red: '#DE350B',
  red100: '#DE350B',
  red80: '#E55D3C',
  red60: '#EB866D',
  red40: '#F2AE9D',
};

const zIndex = {
  below2: -2,
  below: -1,
  above: 1,
  above2: 2,
  above3: 3,
};

const fontFamilies = {
  serif: 'serif',
  'sans-serif': 'DM Sans, sans-serif',
};

const fontSizes = {
  h0: 60,
  h1: 30,
  h2: 22,
  h3: 16,
  h4: 14,
  title: 14,
  button: 14,
  titleSmall: 12,
  body: 16,
  bodySmall: 12,
  label: 12,
  small: 14,
};

const boxShadow = {
  large: '0 16px 32px rgba(0, 0, 0, 0.15)',
};

const borderRadius = {
  huge: 50,
  large: 24,
  medium: 18,
  card: 8,
  button: 8,
  small: 4,
  circle: '50%',
};

export const theme = {
  colors,
  space: createSpacing(),
  breakpoints: createBreakpoints(),
  zIndex,
  fontFamilies,
  fontSizes,
  height: {
    fill: '100%',
  },
  boxShadow,
  borderRadius,
  grid,
};

export default theme;
