import styled from 'styled-components';
import theme from '../../utils/theme';

export const backdrop = styled('div')({
  position: 'fixed',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  overflowX: 'hidden',
  overflowY: 'auto',
  transition: `opacity 250ms ease-in-out`,
  backgroundColor: 'rgba(0,0,0,0.7)',
  opacity: 0,
  zIndex: 10000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  '&[data-enter]': {
    opacity: 1,
  },
});

export const modal = styled('div')({
  width: '100%',
  opacity: 0,
  transition: 'opacity 250ms ease-in-out',
  '&[data-enter]': {
    opacity: 1,
  },
});

export const modalOuter = styled('div')<{ size?: 'small' | 'large' }>(
  ({ size = 'small' }) => ({
    background: theme.colors.white,
    width: '90vw',
    maxWidth: size === 'large' ? 1280 : 1024,
    minHeight: 'clamp(500px, 90vh, 800px)',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.borderRadius.large,
  }),
);

export const inner = styled('div')({
  marginRight: theme.space[2],
  display: 'flex',
  flex: 1,
  paddingTop: theme.space[1.5],
  overflowY: 'auto',
  overflowX: 'hidden',
});

export const content = styled('main')({
  display: 'flex',
  flex: '0 1 auto',
  flexDirection: 'column',
  paddingLeft: theme.space[4],
  width: '100%',
});

export const scrollable = styled('div')({
  flex: '0 1 100%',
  overflow: 'auto',
  paddingRight: theme.space[4],
  paddingBottom: theme.space[2],
});

export const bottom = styled('footer')({
  display: 'flex',
  alignItems: 'center',
  paddingBottom: theme.space[3],
  paddingTop: theme.space[3],
  paddingRight: theme.space[4],
  flex: 1,
});

export const layout = {
  bottom,
  content,
  inner,
  modalOuter,
  scrollable,
};
