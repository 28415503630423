import { gql } from '@apollo/client';

export const FIND_OR_CREATE_CARBON_FOOTPRING = gql`
  mutation FindOrCreateCarbonFootprint(
    $create: CarbonFootprintCreateInput!
    $update: CarbonFootprintUpdateInput!
    $where: CarbonFootprintWhereUniqueInput!
  ) {
    upsertOneCarbonFootprint(create: $create, update: $update, where: $where) {
      id
      year
      completed
      isVerified
      entity {
        id
        name
      }
      drivingData {
        ownedVehicles {
          gasoline
          diesel
          metan
          electricity
          comment
        }
        rentalVehicles {
          gasoline
          diesel
          metan
          electricity
          comment
        }
        taxi {
          gasoline
          diesel
          metan
          electricity
          comment
        }
        machineryAndEquipment {
          gasoline
          diesel
          metan
          electricity
          kerosene
          comment
        }
      }
      energy {
        electricity
        hotWater
        comment
      }
      waste {
        id
        landfill
        composting
        incineration
        plastic
        paper
        glass
        metals
        bulky

        comment
      }
      flights {
        id
        icao {
          id
          total
          comment
        }
        domesticFlights {
          id
          AEYtoRVKtoAEY
          EGStoRVKtoEGS
          HFNtoRVKtoHFN
          IFJtoRVKtoIFJ
          VEYtoRVKtoVEY
          other
          comment
        }
        internationalFlights {
          id
          oceania
          continentalEurope
          scandinavia
          northAmerica
          southAmerica
          africa
          asia
          comment
        }
      }
      carbonOffset {
        quantity
        comment
      }
      entityMetadata {
        id
        propertyArea
        positions
        clients
        comment
      }
    }
  }
`;
