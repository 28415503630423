import { H2, H3, TextLabel } from '../Text';
import { Box } from '../../styles/Box';
import { formatNumber } from '../../utils/formatting';

export default function KPI({
  title,
  value,
  numberColors = false,
}: {
  title: string;
  value: number;
  numberColors?: boolean;
}) {
  const color = numberColors
    ? Math.sign(Number(value)) > 0
      ? 'red100'
      : 'green100'
    : 'black100';

  return (
    <Box $p={4} $textAlign="center" variant="card">
      <TextLabel as="h2" pb={1}>
        {title}
      </TextLabel>
      <H2 as="p" py={1} color={color}>
        {formatNumber(value / 1000)}
      </H2>
      <H3>tonn CO₂</H3>
    </Box>
  );
}
