import styled from 'styled-components';
import { FieldExplanation, H2 } from '../../components/Text';
import Input from '../../components/Input';
import { Box } from '../../styles/Box';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_flights } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { GridContainer, GridItem } from '../../styles/Grid';
import { parseFloatForInputs } from '../../utils/parseFloatForInput';
import { colors } from '../../utils/theme';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';
import NumberWithSeperator from '../../components/Text/NumberWithSeperator';

const Link = styled('a')({
  color: colors.blue80,
  textDecoration: 'underline',

  '&:visited': {
    color: colors.blue80,
  },
});

type Flights = FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_flights;

type HandleChange<T> = <
  OnChange extends UpdateFunction,
  Category extends keyof T,
>(
  onChange: OnChange,
  category: Category,
) => <Type extends keyof T[Category], Value extends T[Category][Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<Flights> = (onChange, category) => {
  return (type, value) => {
    return onChange((oldState) => {
      const newState = { ...oldState };
      newState.flights[category][type] = value;
      return newState;
    });
  };
};

const icao: ModalDataComponent = ({ state, onChange }) => {
  const {
    flights: { icao },
  } = state;

  const handleChange = createChangeHandler(onChange, 'icao');

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>
          Niðurstöður úr{' '}
          <Link href="https://applications.icao.int/icec" target="_blank">
            ICAO reiknivél
          </Link>
        </H2>

        <FieldExplanation>
          Til að skrá upplýsingar um losun gróðurhúsalofttegunda vegna flugferða
          á árinu er nákvæmast er að nota reiknivél
          Alþjóðaflugmálastofnunarinnar (ICAO), en reiknivélin veitir
          upplýsingar um vegalengd og losun. Hægt er að skrá með einföldum hætti
          flugferðir sem farnar voru á árinu og reiknivélin gefur losunartölu
          vegna hverrar flugferðar. Athugið að það er bara hægt að slá inn eina
          ferð (með millilendingum) í einu og því mikilvægt að skrá niðurstöður
          hverrar ferðar í t.d. excel skjal og leggja saman til þess að fá
          heildarlosunartölu vegna flugsamgangna. Heildarlosunartalan er að
          lokum slegin inn í viðeigandi reit hér í losunarreikninum. Sé ekki
          notast við ICAO-reiknivélina er hægt að skrásetja fjölda ferða, bæði
          innanlandsferðir og millilandaferðir á næstu síðu.
        </FieldExplanation>

        <FieldExplanation borderColor="red100">
          ATH: Passa þarf að skrá ekki bæði í ICAO-reiknivélina og fjölda ferða
          því þá reiknast tvöföld losun.
        </FieldExplanation>

        <FieldExplanation>
          Mikilvægt er að útreikningar vegna losunar gróðurhúsalofttegunda frá
          flugvélum séu eins staðlaðir og mögulegt er og í samræmi við
          útreikninga í öðrum löndum. Af þessum ástæðum er mælt með að notast sé
          við aðferðafræði Alþjóðaflugmálastofnunarinnar (ICAO). Reiknivél
          Alþjóðaflugmálastofnunarinnar notast við bestu fáanlegu gögn hverju
          sinni og tekur inn þætti eins og mismunandi gerðir flugvéla og
          eldsneytisnotkun þeirra í hverjum fluglegg fyrir sig, sætanýtingu og
          meðalþyngd farangurs og farms.
        </FieldExplanation>

        <Link href="https://applications.icao.int/icec" target="_blank">
          Opna reiknivél Alþjóðaflugmálastofnunarinnar
        </Link>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr' }} $paddingY={2}>
        <GridItem>
          <NumberFormatInput
            id="flight.icao"
            label={`ICAO (kg CO₂)`}
            value={icao.total}
            placeholder="Losun alls"
            onValueChange={(e) => handleChange('total', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <TextArea
            id="flight.comment"
            type="text"
            label="Athugasemd"
            value={icao.comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const domesticFlights: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    flights: {
      domesticFlights: {
        AEYtoRVKtoAEY,
        EGStoRVKtoEGS,
        HFNtoRVKtoHFN,
        IFJtoRVKtoIFJ,
        VEYtoRVKtoVEY,
        other,
        comment,
      },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'domesticFlights');

  const {
    flightAEY_RVK,
    flightEGS_RVK,
    flightVEY_RVK,
    flightHFN_RVK,
    flightIFJ_RVK,
    flightOther,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Innanlandsflug</H2>
        <FieldExplanation>
          Sé ekki notast við ICAO reiknivélina er hægt að skrásetja fjölda ferða
          í reitina á þessari síðu. Athugið að skráningin miðast við flug fram
          og til baka. Sé einungis farin önnur leið skal skrá 0,5. Losun
          gróðurhúsalofttegunda reiknast sjálfkrafa. Passa þarf að skrá ekki
          bæði í ICAO-reiknivélina og fjölda ferða því þá reiknast tvöföld
          losun.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <Input
            id="flight.AEY-RVK-AEY"
            type="number"
            label={`Akureyri - Reykjavík`}
            sublabel={
              <NumberWithSeperator value={flightAEY_RVK} suffix="kg/ferð" />
            }
            value={AEYtoRVKtoAEY}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('AEYtoRVKtoAEY', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.EGS-RVK-EGS"
            type="number"
            label={`Egilstaðir - Reykjavík`}
            sublabel={
              <NumberWithSeperator value={flightEGS_RVK} suffix="kg/ferð" />
            }
            value={EGStoRVKtoEGS}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('EGStoRVKtoEGS', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.VEY-RVK-VEY"
            type="number"
            label={`Vestmannaeyjar - Reykjavík`}
            sublabel={
              <NumberWithSeperator value={flightVEY_RVK} suffix="kg/ferð" />
            }
            value={HFNtoRVKtoHFN}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('HFNtoRVKtoHFN', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.HFN-RVK-HFN"
            type="number"
            label={`Höfn - Reykjavík`}
            sublabel={
              <NumberWithSeperator value={flightHFN_RVK} suffix="kg/ferð" />
            }
            value={IFJtoRVKtoIFJ}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('IFJtoRVKtoIFJ', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.IFJ-RVK-IFJ"
            type="number"
            label={`Ísafjörður - Reykjavík`}
            sublabel={
              <NumberWithSeperator value={flightIFJ_RVK} suffix="kg/ferð" />
            }
            value={VEYtoRVKtoVEY}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('VEYtoRVKtoVEY', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.other"
            type="number"
            label={`Annað innanlandsflug`}
            sublabel={
              <NumberWithSeperator value={flightOther} suffix="kg/ferð" />
            }
            value={other}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('other', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem $gridColumn="span 2">
          <TextArea
            id="flight.comment"
            type="string"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const internationalFlights: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    flights: {
      internationalFlights: {
        scandinavia,
        continentalEurope,
        northAmerica,
        southAmerica,
        africa,
        asia,
        oceania,
        comment,
      },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'internationalFlights');

  const {
    flightScandinavia_RVK,
    flightContinentalEurope_RVK,
    flightNorthAmerica_RVK,
    flightSouthAmerica_RVK,
    flightAsia_RVK,
    flightAfrica_RVK,
    flightOceania_RVK,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Millilandaflug</H2>
        <FieldExplanation>
          Sé ekki notast við ICAO reiknivélina er hægt að skrásetja fjölda ferða
          í reitina á þessari síðu. Athugið að skráningin miðast við flug fram
          og til baka. Sé einungis farin önnur leið skal skrá 0,5. Losun
          gróðurhúsalofttegunda reiknast sjálfkrafa. Passa þarf að skrá ekki
          bæði í ICAO-reiknivélina og fjölda ferða því þá reiknast tvöföld
          losun.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <Input
            id="flight.scandinavia"
            type="number"
            label={`Skandinavía`}
            sublabel={
              <NumberWithSeperator
                value={flightScandinavia_RVK}
                suffix="kg/ferð"
              />
            }
            value={scandinavia}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('scandinavia', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.continentalEurope"
            type="number"
            label="Meginland Evrópu"
            sublabel={
              <NumberWithSeperator
                value={flightContinentalEurope_RVK}
                suffix="kg/ferð"
              />
            }
            value={continentalEurope}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange(
                'continentalEurope',
                parseFloatForInputs(e.target.value),
              )
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.northAmerica"
            type="number"
            label={`Norður-Ameríka`}
            sublabel={
              <NumberWithSeperator
                value={flightNorthAmerica_RVK}
                suffix="kg/ferð"
              />
            }
            value={northAmerica}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('northAmerica', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.southAmerica"
            type="number"
            label={`Suður-Ameríka`}
            sublabel={
              <NumberWithSeperator
                value={flightSouthAmerica_RVK}
                suffix="kg/ferð"
              />
            }
            value={southAmerica}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('southAmerica', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.asia"
            type="number"
            label={`Asía`}
            sublabel={
              <NumberWithSeperator value={flightAsia_RVK} suffix="kg/ferð" />
            }
            value={asia}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('asia', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.africa"
            type="number"
            label={`Afríka`}
            sublabel={
              <NumberWithSeperator value={flightAfrica_RVK} suffix="kg/ferð" />
            }
            value={africa}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('africa', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem>
          <Input
            id="flight.oceania"
            type="number"
            label={`Eyjaálfa`}
            sublabel={
              <NumberWithSeperator value={flightOceania_RVK} suffix="kg/ferð" />
            }
            value={oceania}
            placeholder="Fjöldi ferða"
            onChange={(e) =>
              handleChange('oceania', parseFloatForInputs(e.target.value))
            }
          />
        </GridItem>
        <GridItem $gridColumn="full">
          <TextArea
            id="flight.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const flights = { icao, domesticFlights, internationalFlights };

export default flights;
