import { NumericFormat } from 'react-number-format';

type NumberWithSeperatorProps = { value: number; suffix: string };

const NumberWithSeperator = ({ value, suffix }: NumberWithSeperatorProps) => {
  return (
    <NumericFormat
      value={value}
      displayType="text"
      thousandSeparator="."
      decimalSeparator=","
      suffix={' ' + suffix}
    />
  );
};

export default NumberWithSeperator;
