import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { media } from '../../utils/media';
import theme from '../../utils/theme';
import { constants } from '../../utils/constants';

export const root = styled('aside')({
  maxWidth: constants.SIDEBAR_WIDTH,
});

export const Logo = styled('img')({
  display: 'block',
  width: '100%',
  paddingBottom: theme.space[4],
  paddingTop: theme.space[4],
});

export const list = styled('ul')({
  display: 'flex',
  flexDirection: 'column',
});

export const link = styled(NavLink)({
  alignItems: 'center',
  display: 'flex',
  paddingBottom: theme.space[2],
  paddingLeft: theme.space[3],
  paddingRight: theme.space[3],
  paddingTop: theme.space[2],
  '&.active': {
    color: theme.colors.blue,
    '&::before': {
      background: theme.colors.blue,
    },
  },
  '&::before': {
    background: 'transparent',
    borderRadius: 32,
    content: "''",
    height: 32,
    left: -8,
    margin: 'auto',
    position: 'absolute',
    width: 12,
  },
  [media['md']]: {
    '&:hover': {
      background: theme.colors.gray5,
    },
  },
});
