import Section from '../components/Section';
import Table from '../components/Table';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
  DocumentsQuery,
  DocumentsQueryVariables,
} from './__generated__/DocumentsQuery';
import Button from '../components/Button/Button';
import styled from 'styled-components';
import React from 'react';
import {
  ConfirmDocumentCollection,
  ConfirmDocumentCollectionVariables,
} from './__generated__/ConfirmDocumentCollection';
import { Text } from '../components/Text';
import { formatDate } from '../utils/formatting';

const DOCUMENT_FRAGMENT = gql`
  fragment DocumentCollectionFragment on DocumentCollection {
    id
    year
    name
    email
    climatePolicy
    climateGoal
    actionPlan
    population
    checklist
    confirmed
    createdAt
    municipality {
      id
      name
    }
  }
`;

const DOCUMENTS_QUERY = gql`
  ${DOCUMENT_FRAGMENT}
  query DocumentsQuery($where: QueryDocumentCollectionsWhereInput) {
    documentCollections(where: $where, orderBy: { createdAt: desc }) {
      ...DocumentCollectionFragment
    }
  }
`;

const CONFIRM_DOCUMENT_MUTATION = gql`
  ${DOCUMENT_FRAGMENT}
  mutation ConfirmDocumentCollection(
    $id: Int!
  ) {
    verifyDocuments(
      id: $id
    ) {
      ...DocumentCollectionFragment
    }
  }
`;

const DocumentAnchor = styled.a`
  color: #0052cc;
  font-weight: 500;
  text-decoration: underline;
`;

type DocumentLinkProps = { href: string; children: React.ReactNode };

const DocumentLink = ({ href, children }: DocumentLinkProps) =>
  href ? (
    <DocumentAnchor href={href} target="_blank">{children}</DocumentAnchor>
  ) : (
    <span>Vantar gögn</span>
  );

export default function VerifyDocuments() {
  const { data, loading } = useQuery<DocumentsQuery, DocumentsQueryVariables>(
    DOCUMENTS_QUERY,
  );

  const [confirmDocument] = useMutation<
    ConfirmDocumentCollection,
    ConfirmDocumentCollectionVariables
  >(CONFIRM_DOCUMENT_MUTATION, {
    // Easiest way to update cache, because relay cannot update lists from mutations:
    // https://www.apollographql.com/docs/react/data/mutations/#include-modified-objects-in-mutation-responses
    refetchQueries: [DOCUMENTS_QUERY, 'DocumentsQuery'],
  });

  if (loading || !data || !data.documentCollections) {
    return null;
  }

  return (
    <>
      <Section>
        <Table horizontalPadding backgroundColor="white">
          <thead>
            <tr>
              <th>Sveitarfélag</th>
              <th>Tímabil</th>
              <th>Ábyrgðaraðili</th>
              <th>Dags skilað</th>
              <th>Fjöldi íbúa</th>
              <th>Loftslagstefna</th>
              <th>Markmiðasetning</th>
              <th>Aðgerðaáætlun</th>
              <th>Gátlisti</th>
            </tr>
          </thead>
          <tbody>
            {data.documentCollections.map((documentCollection) => (
              <tr key={documentCollection.id}>
                <td>{documentCollection.municipality.name}</td>
                <td>{documentCollection.year}</td>
                <td>
                  {documentCollection.email && documentCollection.name ? (
                    <a href={`mailto:${documentCollection.email}`}>
                      {documentCollection.name}
                    </a>
                  ) : (
                    'Vantar nafn og/eða email'
                  )}
                </td>
                <td>{formatDate(documentCollection.createdAt)}</td>
                <td>{documentCollection.population}</td>
                <td>
                  <DocumentLink href={documentCollection.climatePolicy}>
                    Sækja
                  </DocumentLink>
                </td>
                <td>
                  <DocumentLink href={documentCollection.climateGoal}>
                    Sækja
                  </DocumentLink>
                </td>
                <td>
                  <DocumentLink href={documentCollection.actionPlan}>
                    Sækja
                  </DocumentLink>
                </td>
                <td>
                  <DocumentLink href={documentCollection.checklist}>
                    Sækja
                  </DocumentLink>
                </td>
                <td>
                  {documentCollection.confirmed ? (
                    <Text color="green" fontWeight="500">
                      Staðfest
                    </Text>
                  ) : (
                    <Button
                      alignment="end"
                      fillWidth
                      onClick={() =>
                        confirmDocument({
                          variables: {
                            id: documentCollection.id
                          },
                        })
                      }
                    >
                      Staðfesta skil
                    </Button>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Section>
    </>
  );
}
