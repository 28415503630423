import { FieldExplanation, H2 } from '../../components/Text';
import { Box } from '../../styles/Box';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_waste } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { GridContainer, GridItem } from '../../styles/Grid';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';
import NumberWithSeperator from '../../components/Text/NumberWithSeperator';

type WasteType = FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_waste;

type HandleChange<T> = <OnChange extends UpdateFunction>(
  onChange: OnChange,
) => <Type extends keyof T, Value extends T[Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<WasteType> = (onChange) => {
  return (type, value) => {
    return onChange((oldState) => {
      const newState = { ...oldState };
      newState.waste[type] = value;
      return newState;
    });
  };
};

const Waste: ModalDataComponent = ({ state, onChange, globalCoefficients }) => {
  const {
    landfill,
    composting,
    bulky,
    glass,
    metals,
    paper,
    plastic,
    comment,
  } = state.waste;

  const handleChange = createChangeHandler(onChange);

  const {
    wasteLandfill,
    wasteComposting,
    wasteBulky,
    wasteGlass,
    wasteMetal,
    wastePaper,
    wastePlastic,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Úrgangur</H2>
        <FieldExplanation>
          Hér skal skrá árlegt úrgangsmagn eftir meðhöndlun. Upplýsingar um magn
          úrgangs er að fá hjá þjónustuaðila og/eða í bókhaldi. Losun
          gróðurhúsalofttegunda reiknast sjálfkrafa.
        </FieldExplanation>
        <FieldExplanation>
          Ástæða þess að sumir úrgangsflokkar eru með núll í losunarstuðul er sú
          að eins og er liggja þessir úrgangsstraumar úr landi og lendir því
          losunin í losunarbókhaldi þeirra landa þar sem meðhöndlun fer fram en
          ekki losunarbókhaldi Íslands.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="waste.landfill"
            label={`Úrgangur til urðunar (almennur) (kg)`}
            sublabel={
              <NumberWithSeperator value={wasteLandfill} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            required
            value={landfill}
            onValueChange={(e) => handleChange('landfill', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="waste.molta"
            label={`Lífrænn úrgangur (jarðgerð) (kg)`}
            sublabel={
              <NumberWithSeperator
                value={wasteComposting}
                suffix="kg CO₂ / kg"
              />
            }
            placeholder="kg á ári"
            required
            value={composting}
            onValueChange={(e) => handleChange('composting', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="waste.plastic"
            label={`Plast (kg)`}
            sublabel={
              <NumberWithSeperator value={wastePlastic} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            value={plastic}
            onValueChange={(e) => handleChange('plastic', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="waste.paper"
            label={`Pappír/pappi (kg)`}
            sublabel={
              <NumberWithSeperator value={wastePaper} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            value={paper}
            onValueChange={(e) => handleChange('paper', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="waste.glass"
            label={`Gler (kg)`}
            sublabel={
              <NumberWithSeperator value={wasteGlass} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            value={glass}
            onValueChange={(e) => handleChange('glass', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="waste.metals"
            label={`Málmar (kg)`}
            sublabel={
              <NumberWithSeperator value={wasteMetal} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            value={metals}
            onValueChange={(e) => handleChange('metals', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="waste.bulky"
            label={`Grófur úrgangur (kg)`}
            sublabel={
              <NumberWithSeperator value={wasteBulky} suffix="kg CO₂ / kg" />
            }
            placeholder="kg á ári"
            value={bulky}
            onValueChange={(e) => handleChange('bulky', e.floatValue)}
          />
        </GridItem>

        <GridItem $gridColumn="span 2">
          <TextArea
            id="waste.blendedWaste.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export const waste = {
  Waste,
};
