import create from 'zustand';
import { registerLocale } from 'react-datepicker';
import is from 'date-fns/locale/is';
import { immerMiddleware } from './immerMiddleware';
import { driving } from '../modals/categories/driving';
import flight from '../modals/categories/flight';
import { power } from '../modals/categories/power';
import { waste } from '../modals/categories/waste';
import carbonOffset from '../modals/categories/carbonOffset';
import type { AppState, Category } from '../types';
import { metadata } from '../modals/categories/metadata';

const categories: Category[] = [
  {
    title: 'Grunnupplýsingar',
    key: 'metadata',
    iconName: 'setting',
    items: [
      {
        title: 'Grunnupplýsingar',
        key: 'metadata.Metadata',
        component: metadata.MetaData,
      },
    ],
  },
  {
    title: 'Akstur',
    key: 'driving',
    iconName: 'driving',
    status: 'in-progress',
    items: [
      {
        title: 'Eigin bílar',
        key: 'driving.cars',
        component: driving.Cars,
        nextItem: 'driving.rentals',
        error: false,
      },
      {
        title: 'Bílaleigubílar',
        key: 'driving.rentals',
        component: driving.Rentals,
        nextItem: 'driving.taxis',
        error: false,
      },
      {
        title: 'Leigubílar',
        key: 'driving.taxis',
        component: driving.Taxis,
        nextItem: 'driving.heavyMachinery',
        error: false,
      },
      {
        title: 'Vinnuvélar og tæki',
        key: 'driving.heavyMachinery',
        component: driving.HeavyMachinery,
        error: false,
      },
    ],
  },
  {
    title: 'Orka',
    key: 'power',
    iconName: 'electricity',
    status: 'ok',
    items: [
      {
        title: 'Orka',
        key: 'power.electricity',
        component: power.Electricity,
        error: false,
      },
    ],
  },
  {
    title: 'Flug',
    key: 'flight',
    iconName: 'flight',
    status: 'ok',
    items: [
      {
        title: 'ICAO',
        key: 'flight.icao',
        nextItem: 'flight.domestic',
        component: flight.icao,
        error: false,
      },
      {
        title: 'Innanlands',
        key: 'flight.domestic',
        nextItem: 'flight.international',
        component: flight.domesticFlights,
        error: false,
      },
      {
        title: 'Millilanda',
        key: 'flight.international',
        component: flight.internationalFlights,
        error: false,
      },
    ],
  },
  {
    title: 'Úrgangur',
    key: 'waste',
    iconName: 'trash',
    status: 'error',
    items: [
      {
        title: 'Úrgangur',
        key: 'waste.was',
        component: waste.Waste,
        error: false,
      },
    ],
  },
  {
    title: 'Kolefnisjöfnun',
    key: 'carbonOffset',
    iconName: 'forest',
    status: 'ok',
    items: [
      {
        title: 'Kolefnisjöfnun',
        key: 'carbonOffset',
        component: carbonOffset,
        error: false,
      },
    ],
  },
];

const now = new Date();

const store = (set, get): AppState => ({
  categories,
  activeEstablishment: '',
  activeCategory: 'metadata',
  activeItem: 'metadata.Metadata',
  selectedYear: now.getFullYear(),
  showOverview: false,
  actions: {
    initialize: () => {
      registerLocale('is', is);
    },
    setActiveCategory: (key: string) => {
      const categories = get().categories;
      const activeCategory = categories.find((cat) => cat.key === key);
      const firstItem = activeCategory.items[0];
      set(() => ({
        // Set active category to this key
        activeCategory: key,
        // Reset activeItem to the first category item
        activeItem: firstItem.key,
        // hide overview screen if visible
        showOverview: false,
      }));
    },
  },
});

export const useStore = create(immerMiddleware(store));
