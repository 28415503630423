import { LabelHTMLAttributes } from 'react';
import { ResponsiveValue } from 'styled-system';
import theme from '../../utils/theme';
import { BaseText, BaseTextProps } from './Text.styles';

type TextProps = {
  children: React.ReactNode;
  as?: any;
  pb?: ResponsiveValue<number>; // Padding bottom
  pt?: ResponsiveValue<number>; // Padding top
  py?: ResponsiveValue<number>; // Padding vertical
  marginRight?: ResponsiveValue<number>;
  marginLeft?: ResponsiveValue<number>;
  mx?: ResponsiveValue<number>; // Margin horizontal
  color?: keyof typeof theme.colors | 'inherit';
  whiteSpace?: 'nowrap' | 'normal';
  className?: string;
  onClick?: () => void;
} & BaseTextProps;

type LabelProps = TextProps & LabelHTMLAttributes<HTMLLabelElement>;

export const Text = ({
  as,
  children,
  pt,
  pb,
  py,
  pl,
  pr,
  px,
  mx,
  my,
  mb,
  mt,
  marginRight,
  marginLeft,
  lineHeight,
  fontFamily = 'sans-serif',
  fontWeight,
  fontSize = theme.fontSizes.body,
  color = 'inherit' as const,
  ...rest
}: TextProps) => {
  return (
    <BaseText
      as={as}
      pb={py ?? pb}
      pt={py ?? pt}
      pr={px ?? pr}
      pl={px ?? pl}
      mb={my ?? mb}
      mt={my ?? mt}
      mx={mx}
      $fontSize={fontSize}
      $fontWeight={fontWeight}
      $fontFamily={
        theme.fontFamilies[fontFamily as keyof typeof theme.fontFamilies]
      }
      $lineHeight={lineHeight}
      $marginRight={marginRight}
      $marginLeft={marginLeft}
      $color={color}
      {...rest}
    >
      {children}
    </BaseText>
  );
};

export const H1 = ({ children, as = 'h1', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.h1}
      fontWeight={600}
      lineHeight={1.1}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const H2 = ({ children, as = 'h2', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.h2}
      fontWeight={600}
      lineHeight={1.1}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const H3 = ({ children, as = 'h3', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.h3}
      fontWeight={600}
      lineHeight={1.3}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const TextLabel = ({ children, as = 'small', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.label}
      fontWeight={600}
      lineHeight={1.3}
      letterSpacing={1.5}
      uppercase
      {...rest}
    >
      {children}
    </Text>
  );
};

export const LabelText = ({ children, as = 'label', ...rest }: LabelProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.small}
      fontWeight={600}
      lineHeight={1.3}
      letterSpacing={-0.333}
      color="gray"
      {...rest}
    >
      {children}
    </Text>
  );
};

export const Title = ({
  children,
  fontWeight = 600,
  as = 'p',
  ...rest
}: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.title}
      lineHeight={1.1}
      fontWeight={fontWeight}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const TitleSmall = ({ children, as = 'h4', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.titleSmall}
      fontWeight={500}
      lineHeight={1.5}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const Small = ({
  children,
  fontWeight = 500,
  as = 'small',
  ...rest
}: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.small}
      fontWeight={fontWeight}
      lineHeight={1.5}
      {...rest}
    >
      {children}
    </Text>
  );
};

export const ButtonText = ({ children, as = 'p', ...rest }: TextProps) => {
  return (
    <Text
      as={as}
      fontSize={theme.fontSizes.button}
      fontWeight={500}
      lineHeight={1.5}
      {...rest}
    >
      {children}
    </Text>
  );
};
