import Section from '../components/Section';
import Table from '../components/Table';
import { gql, useQuery } from '@apollo/client';
import {
  SettingsQuery,
  SettingsQueryVariables,
} from './__generated__/SettingsQuery';
import { Modal } from '../components/Modal';
import Button from '../components/Button/Button';
import NewUserModal from '../modals/NewUserModal';
import { useUser } from '../context/user';
import { Box } from '../styles/Box';
import SelectMunicipality from '../components/SelectMunicipality';

const SETTINGS_QUERY = gql`
  query SettingsQuery($where: MunicipalityWhereUniqueInput!) {
    municipality(where: $where) {
      id
      name
      managers {
        kennitala
        fullname
      }
    }
  }
`;

export default function Settings() {
  const { municipality } = useUser();

  const { data, loading, refetch } = useQuery<
    SettingsQuery,
    SettingsQueryVariables
  >(SETTINGS_QUERY, { variables: { where: { id: municipality.id } } });

  if (loading || !data || !data.municipality) {
    return null;
  }

  return (
    <>
      <Box
        as="header"
        $display="flex"
        $alignItems="flex-start"
        $justifyContent="space-between"
        $flex={0}
      >
        <SelectMunicipality />
        <Box $display="flex">
          <Modal
            baseId="create-entity-modal"
            disclosure={
              <Button alignment="end" fillWidth>
                Nýr notandi
              </Button>
            }
          >
            {({ modal }) => (
              <NewUserModal modal={modal} onCreated={() => refetch()} />
            )}
          </Modal>
        </Box>
      </Box>
      <Section>
        <Table horizontalPadding backgroundColor="white">
          <thead>
            <tr>
              <th>Nafn</th>
              <th>Kennitala</th>
            </tr>
          </thead>
          {data.municipality.managers.map((manager) => (
            <tr key={manager.kennitala}>
              <td>{manager.fullname}</td>
              <td>{manager.kennitala}</td>
            </tr>
          ))}
        </Table>
      </Section>
    </>
  );
}
