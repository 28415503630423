import { grid as systemGrid, system, compose, Config } from 'styled-system';

const config: Config = {
  gridColumn: {
    property: 'gridColumn',
    transform: value => {
      if (value === 'full') {
        return '1 / -1';
      }
      return value;
    },
  },
};

export const grid = compose(systemGrid, system(config));
