import { gql } from '@apollo/client';

export const GLOBAL_COEFFICIENTS_FRAGMENT = gql`
  fragment CoefficientFragment on GlobalCoefficients {
    year
    ownedVehiclesGasoline
    ownedVehiclesDiesel
    ownedVehiclesMetan
    ownedVehiclesElectricity
    rentalVehiclesGasoline
    rentalVehiclesDiesel
    rentalVehiclesMetan
    rentalVehiclesElectricity
    taxiGasoline
    taxiDiesel
    taxiMetan
    taxiElectricity
    machineryGasoline
    machineryDiesel
    machineryMetan
    machineryElectricity
    machineryKerosene
    energyElectricity
    energyHotWater
    flightAEY_RVK
    flightEGS_RVK
    flightVEY_RVK
    flightHFN_RVK
    flightIFJ_RVK
    flightOther
    flightOceania_RVK
    flightContinentalEurope_RVK
    flightScandinavia_RVK
    flightNorthAmerica_RVK
    flightSouthAmerica_RVK
    flightAfrica_RVK
    flightAsia_RVK
    wasteLandfill
    wasteComposting
    wasteIncineration
    wastePlastic
    wastePaper
    wasteGlass
    wasteMetal
    wasteBulky
  }
`;

export const FILTERED_GLOBAL_COEFFICIENTS_QUERY = gql`
  ${GLOBAL_COEFFICIENTS_FRAGMENT}
  query FilteredGlobalCoefficients($where: QueryGlobalCoefficientsWhereInput!) {
    globalCoefficients(where: $where) {
      ...CoefficientFragment
    }
  }
`;

export const ALL_GLOBAL_COEFFICIENTS_QUERY = gql`
  ${GLOBAL_COEFFICIENTS_FRAGMENT}
  query AllGlobalCoefficients {
    globalCoefficients(orderBy: { year: desc }) {
      ...CoefficientFragment
    }
  }
`;

export const GLOBAL_COEFFICIENTS_MUTATION = gql`
  ${GLOBAL_COEFFICIENTS_FRAGMENT}
  mutation GlobalCoefficientsMutation(
    $where: GlobalCoefficientsWhereUniqueInput!
    $data: GlobalCoefficientsUpdateInput!
  ) {
    updateOneGlobalCoefficients(where: $where, data: $data) {
      ...CoefficientFragment
    }
  }
`;
