import React, {
  FC,
  Ref,
  forwardRef,
  useState,
  useLayoutEffect,
  ReactElement,
  useEffect,
  Fragment,
  useCallback,
} from 'react';
import {
  useDialogState,
  Dialog as BaseDialog,
  DialogBackdrop,
  DialogDisclosure,
  DialogProps,
} from 'reakit/Dialog';
import { DisclosureProps } from 'reakit/ts';
import styled from 'styled-components';
import * as styles from './Modal.styles';

export const BackdropDiv = forwardRef(
  (props: DialogProps, ref: Ref<HTMLDivElement>) => {
    const [mounted, setMounted] = useState(false);
    useLayoutEffect(function () {
      setMounted(true);
    }, []);

    return mounted ? <styles.backdrop ref={ref} {...props} /> : null;
  },
);

export type ModalBaseProps = {
  /**
   * Element that opens the dialog.
   * It will be forwarded necessary props for a11y and event handling.
   */
  disclosure?: any; // ReactElement;
  /**
   * Unique ID for accessibility purposes
   */
  baseId: string;
  className?: string;
  /**
   * Default visibility state
   */
  initialVisibility?: boolean;
  /**
   * Setting this to false automatically closes the modal
   */
  toggleClose?: boolean;
  /**
   * Optional cb function that is fired when the modal visibility changes
   */
  onVisibilityChange?: (isVisible: boolean) => void;
  renderDisclosure?: (
    disclosure: ReactElement,
    disclosureProps?: DisclosureProps,
  ) => ReactElement;
  backdropWhite?: boolean;
  /**
   * Aria label for the modal
   */
  modalLabel?: string;
  /**
   * Remove the modal from dom when closed
   */
  removeOnClose?: boolean;
  /**
   * toggle visibility, useful for controlling visibility from useState. Should be used with onVisibilityChange
   */
  isVisible?: boolean;
  /**
   * Clicking outside the Dialog closes it unless hideOnClickOutside is set to false.
   */
  hideOnClickOutside?: boolean;
  /**
   * When there is no focusable element in the dialog the tabIndex should be set to 0.
   */
  tabIndex?: number;
  /**
   * When enabled, the dialog can be closed by pressing Escape. Disabled by default.
   */
  hideOnEsc?: boolean;
};

export type NavigationItem = {
  title: string;
  children: string[] | null;
};

export type SidebarItems = string[] | null;

interface ModalProps extends ModalBaseProps {}

const Dialog = styled(BaseDialog)`
  transition: opacity 600ms;
  opacity: 0;
  &[data-enter] {
    opacity: 1;
  }
`;

export const Modal: FC<ModalProps> = ({
  disclosure,
  baseId,
  initialVisibility,
  toggleClose,
  children,
  onVisibilityChange,
  renderDisclosure = (disclosure) => disclosure,
  modalLabel,
  removeOnClose = true,
  isVisible,
  hideOnClickOutside,
  tabIndex,
  hideOnEsc = true,
}) => {
  const modal = useDialogState({
    animated: true,
    baseId,
    visible: initialVisibility || false,
  });
  const closeModal = useCallback(() => modal.hide(), [modal]);

  // If the toggleClose flag has been set to true, we close the modal
  useEffect(() => {
    if (toggleClose) closeModal();
  }, [closeModal, toggleClose]);

  useEffect(() => {
    if (isVisible) {
      modal.show();
    } else if (isVisible === false) {
      modal.hide();
    }
  }, [isVisible, modal]);

  useEffect(() => {
    onVisibilityChange && onVisibilityChange(modal.visible);
  }, [modal.visible, onVisibilityChange]);

  const renderModal = !removeOnClose || (removeOnClose && modal.visible);

  return (
    <Fragment key="modal">
      {disclosure ? (
        <DialogDisclosure ref={disclosure.ref} {...modal} {...disclosure.props}>
          {(disclosureProps: DisclosureProps) =>
            renderDisclosure(
              React.cloneElement(disclosure, disclosureProps),
              disclosureProps,
            )
          }
        </DialogDisclosure>
      ) : null}
      {renderModal && (
        <DialogBackdrop {...modal} as={BackdropDiv}>
          <Dialog
            {...modal}
            aria-label={modalLabel}
            hideOnClickOutside={hideOnClickOutside}
            tabIndex={tabIndex}
            hideOnEsc={hideOnEsc}
            data-root-modal
          >
            {typeof children === 'function' ? children({ modal }) : children}
          </Dialog>
        </DialogBackdrop>
      )}
    </Fragment>
  );
};
