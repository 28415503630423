import { FilteredGlobalCoefficients_globalCoefficients } from '../queries/__generated__/FilteredGlobalCoefficients';
import { GetMunicipalityData_municipality_entities_carbonFootprintByYear } from '../queries/__generated__/GetMunicipalityData';

export const calculateEmission = (
  data: GetMunicipalityData_municipality_entities_carbonFootprintByYear,
  globalCoefficients: FilteredGlobalCoefficients_globalCoefficients,
) => {
  const {
    drivingData: { ownedVehicles, rentalVehicles, taxi, machineryAndEquipment },
    flights: { domesticFlights, internationalFlights, icao },
    waste,
    energy,
    carbonOffset,
  } = data;

  const ownedVehiclesFootprint =
    ownedVehicles.gasoline * globalCoefficients.ownedVehiclesGasoline +
    ownedVehicles.diesel * globalCoefficients.ownedVehiclesDiesel +
    ownedVehicles.metan * globalCoefficients.ownedVehiclesMetan +
    ownedVehicles.electricity * globalCoefficients.ownedVehiclesElectricity;

  const rentalVehiclesFootprint =
    rentalVehicles.gasoline * globalCoefficients.rentalVehiclesGasoline +
    rentalVehicles.diesel * globalCoefficients.rentalVehiclesDiesel +
    rentalVehicles.metan * globalCoefficients.rentalVehiclesMetan +
    rentalVehicles.electricity * globalCoefficients.rentalVehiclesElectricity;

  const taxiFootprint =
    taxi.gasoline * globalCoefficients.taxiGasoline +
    taxi.diesel * globalCoefficients.taxiDiesel +
    taxi.metan * globalCoefficients.taxiMetan +
    taxi.electricity * globalCoefficients.taxiElectricity;

  const machineryAndEquipmentFootprint =
    machineryAndEquipment.gasoline * globalCoefficients.rentalVehiclesGasoline +
    machineryAndEquipment.diesel * globalCoefficients.rentalVehiclesDiesel +
    machineryAndEquipment.metan * globalCoefficients.rentalVehiclesMetan +
    machineryAndEquipment.electricity *
      globalCoefficients.rentalVehiclesElectricity +
    machineryAndEquipment.kerosene * globalCoefficients.machineryKerosene;

  const energyElectricityFootprint =
    energy.electricity * globalCoefficients.energyElectricity;

  const energyHotWaterFootprint =
    energy.hotWater * globalCoefficients.energyHotWater;

  const domesticFlightsFootprint =
    domesticFlights.AEYtoRVKtoAEY * globalCoefficients.flightAEY_RVK +
    domesticFlights.EGStoRVKtoEGS * globalCoefficients.flightEGS_RVK +
    domesticFlights.HFNtoRVKtoHFN * globalCoefficients.flightHFN_RVK +
    domesticFlights.IFJtoRVKtoIFJ * globalCoefficients.flightIFJ_RVK +
    domesticFlights.VEYtoRVKtoVEY * globalCoefficients.flightVEY_RVK +
    domesticFlights.other * globalCoefficients.flightOther;

  const internationalFlightsFootprint =
    internationalFlights.scandinavia *
      globalCoefficients.flightScandinavia_RVK +
    internationalFlights.continentalEurope *
      globalCoefficients.flightContinentalEurope_RVK +
    internationalFlights.northAmerica *
      globalCoefficients.flightNorthAmerica_RVK +
    internationalFlights.southAmerica *
      globalCoefficients.flightSouthAmerica_RVK +
    internationalFlights.asia * globalCoefficients.flightAsia_RVK +
    internationalFlights.africa * globalCoefficients.flightAfrica_RVK +
    internationalFlights.oceania * globalCoefficients.flightOceania_RVK;

  const landfillFootprint = waste.landfill * globalCoefficients.wasteLandfill;
  const compostingFootprint =
    waste.composting * globalCoefficients.wasteComposting;
  const incinerationFootprint =
    waste.incineration * globalCoefficients.wasteIncineration;
  const plasticFootprint = waste.plastic * globalCoefficients.wastePlastic;
  const paperFootprint = waste.paper * globalCoefficients.wastePaper;
  const glassFootprint = waste.glass * globalCoefficients.wasteGlass;
  const metalsFootprint = waste.metals * globalCoefficients.wasteMetal;
  const bulkyWasteFootprint = waste.bulky * globalCoefficients.wasteBulky;

  return {
    driving: {
      ownedVehiclesFootprint,
      rentalVehiclesFootprint,
      taxiFootprint,
      machineryAndEquipmentFootprint,
    },
    flight: {
      icaoFootprint: icao.total,
      domesticFlightsFootprint,
      internationalFlightsFootprint,
    },
    energy: {
      energyElectricityFootprint,
      energyHotWaterFootprint,
    },
    waste: {
      landfillFootprint,
      compostingFootprint,
      incinerationFootprint,
      plasticFootprint,
      paperFootprint,
      glassFootprint,
      metalsFootprint,
      bulkyWasteFootprint,
    },
    carbonOffset,
  };
};
