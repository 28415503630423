import { GridItem } from '../../styles/Grid';
import Section from '../Section';
import { Text } from '../Text';

export default function NotEnoughData({
  heading = 'Ekki næg gögn til að birta yfirlit',
  text = 'Skila þarf inn gögnum fyrir a.m.k. eina rekstrareiningu til að hægt sé að birta yfirlit',
}) {
  return (
    <Section heading={heading}>
      <GridItem $gridColumn="full">
        <Text>{text}</Text>
      </GridItem>
    </Section>
  );
}
