import { FieldExplanation, H2 } from '../../components/Text';
import { Box } from '../../styles/Box';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_entityMetadata } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { GridContainer, GridItem } from '../../styles/Grid';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';

type EntityMetadata =
  FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_entityMetadata;

type HandleChange<T> = <OnChange extends UpdateFunction>(
  onChange: OnChange,
) => <Type extends keyof T, Value extends T[Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<EntityMetadata> = (onChange) => {
  return (type, value) => {
    return onChange((oldState) => {
      const newState = { ...oldState };
      newState.entityMetadata[type] = value;
      return newState;
    });
  };
};

const MetaData: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    entityMetadata: { propertyArea, positions, clients, comment },
  } = state;

  const handleChange = createChangeHandler(onChange);

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Grunnupplýsingar</H2>
        <FieldExplanation>
          <p>
            Skrá þarf fermetrafjölda húsnæða, fjölda stöðugilda og fjölda
            notenda sem nýta húsnæðin.
          </p>

          <p>Stöðugildi: fjöldi starfsmanna</p>

          <p>
            Aðrir notendur: t.d. nemendur í grunnskólum eða gestir í sundlaugum
            (meðalfjöldi per dag)
          </p>
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="metadata.propertyArea"
            label="Fermetrafjöldi fasteigna (m²)"
            placeholder="Fermetrar"
            value={propertyArea}
            onValueChange={(e) => handleChange('propertyArea', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="metadata.positions"
            label="Stöðugildi"
            placeholder="Meðalfjöldi stöðugilda þetta árið"
            value={positions}
            onValueChange={(e) => handleChange('positions', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="metadata.clients"
            label="Notendur"
            placeholder="Meðalfjöldi notenda sem nýta fasteignir"
            value={clients}
            onValueChange={(e) => handleChange('clients', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <TextArea
            id="metadata.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export const metadata = {
  MetaData,
};
