import { FieldExplanation, H2 } from '../../components/Text';
import { Box } from '../../styles/Box';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_drivingData } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { GridContainer, GridItem } from '../../styles/Grid';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';
import NumberWithSeperator from '../../components/Text/NumberWithSeperator';

type DrivingData =
  FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_drivingData;

type HandleChange<T> = <
  OnChange extends UpdateFunction,
  Category extends keyof T,
>(
  onChange: OnChange,
  category: Category,
) => <Type extends keyof T[Category], Value extends T[Category][Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<DrivingData> = (onChange, category) => {
  return (type, value) => {
    return onChange((oldState) => {
      const newState = { ...oldState };
      newState.drivingData[category][type] = value;
      return newState;
    });
  };
};

const Cars: ModalDataComponent = ({ state, onChange, globalCoefficients }) => {
  const {
    drivingData: {
      ownedVehicles: { gasoline, diesel, electricity, metan, comment },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'ownedVehicles');

  const {
    ownedVehiclesDiesel,
    ownedVehiclesElectricity,
    ownedVehiclesGasoline,
    ownedVehiclesMetan,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Eldsneyti á eigin bíla</H2>
        <FieldExplanation>
          Hér skal skrá upplýsingar um notkun eldsneytis vegna aksturs eigin
          bíla á árinu. Upplýsingar má finna í bókhaldi eða með því að óska
          eftir yfirliti frá birgjum/þjónustuaðilum. Losun gróðurhúsalofttegunda
          reiknast sjálfkrafa. Tilgangurinn með þessari skráningu er að halda
          utan um magn jarðefnaeldsneytis sem sveitarfélagið notar vegna aksturs
          og þeirri losun gróðurhúsalofttegunda sem það veldur.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="driving.cars.petrolLiters"
            label={`Bensín (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={ownedVehiclesGasoline}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            required
            value={gasoline}
            onValueChange={(e) => handleChange('gasoline', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.cars.dieselLiters"
            label={`Dísill (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={ownedVehiclesDiesel}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            required
            value={diesel}
            onValueChange={(e) => handleChange('diesel', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.cars.metanM3"
            label={`Metan (m³)`}
            sublabel={
              <NumberWithSeperator
                value={ownedVehiclesMetan}
                suffix="kg CO₂ / rúmmetra"
              />
            }
            placeholder={'Rúmmetrar á ári'}
            required
            value={metan}
            onValueChange={(e) => handleChange('metan', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.cars.electricityKwh"
            label={`Rafmagn (kWh)`}
            sublabel={
              <NumberWithSeperator
                value={ownedVehiclesElectricity}
                suffix="kg CO₂ / kWh"
              />
            }
            placeholder={'kWh á ári'}
            required
            value={electricity}
            onValueChange={(e) => handleChange('electricity', e.floatValue)}
          />
        </GridItem>
        <GridItem $gridColumn="full">
          <TextArea
            id="driving.cars.comment"
            type="string"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const Rentals: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    drivingData: {
      rentalVehicles: { gasoline, diesel, electricity, metan, comment },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'rentalVehicles');

  const {
    rentalVehiclesDiesel,
    rentalVehiclesElectricity,
    rentalVehiclesGasoline,
    rentalVehiclesMetan,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Eldsneyti á bílaleigubíla</H2>
        <FieldExplanation>
          Hér skal skrá upplýsingar um notkun eldsneytis vegna aksturs
          bílaleigubíla á árinu. Upplýsingar má finna í bókhaldi eða með því að
          óska eftir yfirliti frá birgjum/þjónustuaðilum. Losun
          gróðurhúsalofttegunda reiknast sjálfkrafa. Tilgangurinn með þessari
          skráningu er að halda utan um magn jarðefnaeldsneytis sem
          sveitarfélagið notar vegna aksturs og þeirri losun
          gróðurhúsalofttegunda sem það veldur.
        </FieldExplanation>
        <FieldExplanation borderColor="red100">
          ATH: Hér þarf að passa upp á tvítalningu. Ef eldsneytisnotkun vegna
          eigin bíla og bílaleigubíla er ekki aðskilin í bókhaldinu skal
          einungis skrá öðrum megin, t.d. einungis í reitinn fyrir
          eldsneytisnotkun á eigin bíla.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="driving.rentals.petrolLiters"
            label={`Bensín (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={rentalVehiclesGasoline}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            required
            value={gasoline}
            onValueChange={(e) => handleChange('gasoline', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.rentals.dieselLiters"
            label={`Dísill (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={rentalVehiclesDiesel}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            required
            value={diesel}
            onValueChange={(e) => handleChange('diesel', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.rentals.metanM3"
            label={`Metan (m³)`}
            sublabel={
              <NumberWithSeperator
                value={rentalVehiclesMetan}
                suffix="kg CO₂ / m³"
              />
            }
            placeholder={'Rúmmetrar á ári'}
            required
            value={metan}
            onValueChange={(e) => handleChange('metan', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.rentals.electricityKwh"
            label={`Rafmagn (kWh)`}
            sublabel={
              <NumberWithSeperator
                value={rentalVehiclesElectricity}
                suffix="kg CO₂ / kWh"
              />
            }
            placeholder={'kWh á ári'}
            value={electricity}
            onValueChange={(e) => handleChange('electricity', e.floatValue)}
          />
        </GridItem>
        <GridItem $gridColumn="span 2">
          <TextArea
            id="driving.rentals.comment"
            type="string"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const Taxis: ModalDataComponent = ({ state, onChange, globalCoefficients }) => {
  const {
    drivingData: {
      taxi: { diesel, gasoline, electricity, metan, comment },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'taxi');

  const { taxiGasoline, taxiElectricity, taxiDiesel, taxiMetan } =
    globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Leigubílar</H2>
        <FieldExplanation>
          Þegar kemur að akstri er mun nákvæmara að reikna losun
          gróðurhúsalofttegunda út frá magni eldsneytis en ekinni vegalengd. Ef
          upplýsingar um eldsneytisnotkun liggja ekki fyrir, eins og gæti verið
          í tilviki leigubíla, skal skrá upplýsingar um eknar vegalengdir á
          árinu. Upplýsingar má nálgast með því að óska eftir yfirliti frá
          þjónustuaðilum.
        </FieldExplanation>
        <FieldExplanation borderColor="red100">
          ATH: Ef leigubílaþjónustur geta ekki veitt nákvæmar upplýsingar um
          eldsneytisnotkun þeirra leigubíla sem notaðir voru er hægt að biðja um
          samsetningu bílaflotans hjá fyrirtækinu og nota þær upplýsingar til
          grundvallar.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="driving.taxis.petrolLiters"
            label={`Bensín (km)`}
            sublabel={
              <NumberWithSeperator value={taxiGasoline} suffix="kg CO₂ / km" />
            }
            placeholder={'Kílómetrar á ári'}
            required
            value={gasoline}
            onValueChange={(e) => handleChange('gasoline', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.taxis.dieselLiters"
            label={`Dísill (km)`}
            sublabel={
              <NumberWithSeperator value={taxiDiesel} suffix="kg CO₂ / km" />
            }
            placeholder={'Kílómetrar á ári'}
            required
            value={diesel}
            onValueChange={(e) => handleChange('diesel', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.taxis.metanM3"
            label={`Metan (km)`}
            sublabel={
              <NumberWithSeperator value={taxiMetan} suffix="kg CO₂ / km" />
            }
            placeholder={'Kílómetrar á ári'}
            required
            value={metan}
            onValueChange={(e) => handleChange('metan', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.taxis.electricityKwh"
            label={`Rafmagn (km)`}
            sublabel={
              <NumberWithSeperator
                value={taxiElectricity}
                suffix="kg CO₂ / km"
              />
            }
            placeholder={'Kílómetrar á ári'}
            value={electricity}
            onValueChange={(e) => handleChange('electricity', e.floatValue)}
          />
        </GridItem>
        <GridItem $gridColumn="span 2">
          <TextArea
            id="driving.taxis.comment"
            type="string"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

const HeavyMachinery: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    drivingData: {
      machineryAndEquipment: {
        diesel,
        electricity,
        gasoline,
        metan,
        kerosene,
        comment,
      },
    },
  } = state;

  const handleChange = createChangeHandler(onChange, 'machineryAndEquipment');

  const {
    machineryDiesel,
    machineryElectricity,
    machineryGasoline,
    machineryKerosene,
    machineryMetan,
  } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Vinnuvélar og tæki</H2>
        <FieldExplanation>
          Hér skal skrá upplýsingar um notkun eldsneytis vegna vinnuvéla og
          tækja á árinu. Upplýsingar má finna í bókhaldi eða með því að óska
          eftir yfirliti frá birgjum/þjónustuaðilum. Losun gróðurhúsalofttegunda
          reiknast sjálfkrafa. Tilgangurinn með þessari skráningu er að halda
          utan um magn jarðefnaeldsneytis sem sveitarfélagið notar vegna aksturs
          og þeirri losun gróðurhúsalofttegunda sem það veldur.
        </FieldExplanation>
        <FieldExplanation borderColor="red100">
          ATH: Hér þarf að passa upp á tvítalningu. Ef eldsneytisnotkun vegna
          eigin bíla og vinnuvéla og tækja er ekki aðskilin í bókhaldinu skal
          einungis skrá öðrum megin, t.d. einungis í reitinn fyrir
          eldsneytisnotkun á eigin bíla.
        </FieldExplanation>
      </Box>

      <GridContainer $gridTemplateColumns={{ md: '1fr 1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="driving.heavyMachinery.petrolLiters"
            label={`Bensín (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={machineryGasoline}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            value={gasoline}
            onValueChange={(e) => handleChange('gasoline', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.heavyMachinery.dieselLiters"
            label={`Dísill (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={machineryDiesel}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            value={diesel}
            onValueChange={(e) => handleChange('diesel', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.heavyMachinery.metanM3"
            label={`Metan (m³)`}
            sublabel={
              <NumberWithSeperator
                value={machineryMetan}
                suffix="kg CO₂ / m³"
              />
            }
            placeholder={'Rúmmetrar á ári'}
            value={metan}
            onValueChange={(e) => handleChange('metan', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <NumberFormatInput
            id="driving.heavyMachinery.electricityKwh"
            label={`Rafmagn (kWh)`}
            sublabel={
              <NumberWithSeperator
                value={machineryElectricity}
                suffix="kg CO₂ / MWh"
              />
            }
            placeholder={'mWh á ári'}
            value={electricity}
            onValueChange={(e) => handleChange('electricity', e.floatValue)}
          />
        </GridItem>

        <GridItem>
          <NumberFormatInput
            id="driving.heavyMachinery.kerosene"
            label={`Steinolía (lítrar)`}
            sublabel={
              <NumberWithSeperator
                value={machineryKerosene}
                suffix="kg CO₂ / lítra"
              />
            }
            placeholder={'Lítrar á ári'}
            value={kerosene}
            onValueChange={(e) => handleChange('kerosene', e.floatValue)}
          />
        </GridItem>
        <GridItem $gridColumn="full">
          <TextArea
            id="driving.heavyMachinery.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export const driving = {
  Cars,
  Rentals,
  Taxis,
  HeavyMachinery,
};
