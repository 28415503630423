import styled from 'styled-components';
import theme from '../../utils/theme';

export const root = styled('div')({
  flex: 1,
  height: '100%',
  '& + &': {
    marginLeft: theme.space[2],
  },
  '&:first-child:not(:last-child)': {
    marginRight: theme.space[2],
  },
});

export const inputWrap = styled('div')<{ focused: boolean }>(({ focused }) => ({
  borderBottom: `1px solid ${
    focused ? theme.colors.blue : theme.colors.gray40
  }`,
  height: '100%',
  paddingBottom: theme.space[1],
  marginBottom: theme.space[0.5],
  paddingTop: theme.space[2],
}));

export const input = styled('input')({
  outline: 0,
  appearance: 'none',
  border: 0,
  fontSize: 16,
  fontWeight: 600,
  paddingBottom: theme.space[1],
  paddingTop: theme.space[1],
  width: '100%',
});

export const circleInputWrap = styled('div')<{ focused: boolean }>(
  ({ focused }) => ({
    flex: 1,
    paddingBottom: theme.space[1],
    paddingTop: theme.space[2],
    '& + &': {
      marginLeft: theme.space[2],
    },
    '&:first-child:not(:last-child)': {
      marginRight: theme.space[2],
    },
  }),
);

export const circleInput = styled('input')({
  display: 'flex',
  outline: 0,
  appearance: 'none',
  border: 0,
  borderRadius: 5,
  fontSize: 14,
  fontWeight: 300,
  padding: theme.space[1],
  backgroundColor: '#e4e3e8',
});

export const textAreaWrap = styled('div')<{ focused: boolean }>(
  ({ focused }) => ({
    border: `1px solid ${focused ? theme.colors.blue : theme.colors.gray40}`,
    borderRadius: 5,
  }),
);

export const textArea = styled('textarea')({
  display: 'flex',
  height: '100px',
  width: '100%',
  maxWidth: '100%',
  resize: 'vertical',
  fontFamily: 'Arial',
  outline: 0,
  appearance: 'none',
  border: 0,
  borderRadius: 5,
  fontSize: 14,
  fontWeight: 300,
  padding: theme.space[1],
});
