import * as React from 'react';
import Section from '../components/Section';
import Table from '../components/Table';
import Button from '../components/Button/Button';
import { Modal } from '../components/Modal';
import { DataModal } from '../modals/DataModal';
import { GridItem } from '../styles/Grid';
import { useQuery } from '@apollo/client';
import { EntityModal } from '../modals/EntityModal';
import { GET_ENTITIES_IN_MUNICIPALITY } from '../queries/getEntitiesInMunicipality';
import {
  GetEntitiesInMunicipality,
  GetEntitiesInMunicipalityVariables,
  GetEntitiesInMunicipality_municipality,
  GetEntitiesInMunicipality_municipality_entities,
} from '../queries/__generated__/GetEntitiesInMunicipality';
import { Box } from '../styles/Box';
import theme from '../utils/theme';
import { formatDate } from '../utils/formatting';
import { useUser } from '../context/user';
import SelectMunicipality from '../components/SelectMunicipality';
import yearRange from '../utils/yearRange';

type EntityRowProps = {
  entity: GetEntitiesInMunicipality_municipality_entities;
  municipality: GetEntitiesInMunicipality_municipality;
};

const EntityRow = ({ entity, municipality }: EntityRowProps) => {
  const [chosenYear, setChosenYear] = React.useState<{
    entityId: number;
    year: number;
  }>();
  const currentYear = new Date().getFullYear();
  const years = [...yearRange(currentYear, 2010)];

  return (
    <tr key={entity.id}>
      <td>{entity.name}</td>
      <td>{formatDate(entity.updatedAt)}</td>
      <Modal
        baseId="establishment-modal"
        disclosure={
          <Box $display="flex" $gridColumnGap={theme.grid.gap} as="td">
            {years.map((year) => {
              const carbonFootprint = entity.carbonFootprintByYear.find(
                (carbonFootprint) => carbonFootprint.year === year,
              );
              const color = !carbonFootprint
                ? 'yellow'
                : carbonFootprint.isVerified
                ? 'blue'
                : carbonFootprint.completed
                ? 'green'
                : 'yellow';
              return (
                <Button
                  key={year}
                  variant="pill"
                  color={color}
                  fillWidth
                  onClick={() => setChosenYear({ entityId: entity.id, year })}
                >
                  {year}
                </Button>
              );
            })}
          </Box>
        }
      >
        {({ modal }) => {
          return <DataModal modal={modal} chosenYear={chosenYear} />;
        }}
      </Modal>
      <td>
        <Modal
          baseId="edit-entity-modal"
          disclosure={
            <Button
              variant="pill"
              icon="setting"
              iconSize={18}
              iconPosition="after"
            ></Button>
          }
        >
          {({ modal }) => (
            <EntityModal
              modal={modal}
              municipality={municipality}
              entityId={entity.id}
            />
          )}
        </Modal>
      </td>
    </tr>
  );
};

export default function Establishment() {
  const { municipality } = useUser();

  const { data, loading, refetch } = useQuery<
    GetEntitiesInMunicipality,
    GetEntitiesInMunicipalityVariables
  >(GET_ENTITIES_IN_MUNICIPALITY, {
    variables: { where: { id: municipality.id } },
  });

  if (loading || typeof data === 'undefined') {
    return null;
  }

  return (
    <>
      <Box
        as="header"
        $display="flex"
        $alignItems="flex-start"
        $justifyContent="space-between"
        $flex={0}
      >
        <SelectMunicipality />
        <Box $display="flex">
          <Modal
            baseId="create-entity-modal"
            disclosure={
              <Button alignment="end" fillWidth>
                Ný rekstrareining
              </Button>
            }
          >
            {({ modal }) => (
              <EntityModal
                modal={modal}
                municipality={data.municipality}
                onCreateNewEntity={() => {
                  refetch();
                }}
              />
            )}
          </Modal>
        </Box>
      </Box>

      <Section>
        {data.municipality.entities.length === 0 ? (
          <GridItem $gridColumn="span 2">Engin skráð rekstrareining.</GridItem>
        ) : (
          <Table horizontalPadding centered backgroundColor="white">
            <thead>
              <tr>
                <th>Stofnun</th>
                <th>Síðast uppfært</th>
                <th>Skráning</th>
                <th>Stillingar</th>
              </tr>
            </thead>
            {data.municipality.entities.map((entity) => (
              <EntityRow
                key={entity.id}
                municipality={data.municipality}
                entity={entity}
              />
            ))}
          </Table>
        )}
      </Section>
    </>
  );
}
