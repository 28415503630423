export const formatNumber = (number, fractionDigits = 1) => {
  const numberFormat = new Intl.NumberFormat('de', {
    maximumFractionDigits: fractionDigits,
    minimumFractionDigits: fractionDigits,
  });
  return numberFormat.format(number);
}

export const formatDate = (date) => {
  const dateFormat = Intl.DateTimeFormat('de');
  return dateFormat.format(new Date(date));
}
