import { useCallback, useState } from 'react';
import { useQuery } from '@apollo/client';
import Section from '../components/Section';
import KPI from '../components/KPI';
import Button from '../components/Button/Button';
import { Modal } from '../components/Modal';
import { DataModal } from '../modals/DataModal';
import DocumentsModal from '../modals/DocumentsModal';
import { GET_DASHBOARD_DATA_QUERY } from '../queries/getDashboardData';
import { FILTERED_GLOBAL_COEFFICIENTS_QUERY } from '../queries/globalCoefficients';
import {
  FilteredGlobalCoefficients,
  FilteredGlobalCoefficientsVariables,
  FilteredGlobalCoefficients_globalCoefficients,
} from '../queries/__generated__/FilteredGlobalCoefficients';
import {
  GetMunicipalityData,
  GetMunicipalityDataVariables,
  GetMunicipalityData_municipality_entities_carbonFootprintByYear,
} from '../queries/__generated__/GetMunicipalityData';
import { calculateEmission } from '../utils/calculateEmission';
import OverviewPieChart from '../charts/OverviewPieChart';
import Select from '../components/Select';
import { Text } from '../components/Text';
import { Box } from '../styles/Box';
import NotEnoughData from '../components/NotEnoughData';
import { useUser } from '../context/user';
import SelectMunicipality from '../components/SelectMunicipality';
import { GridItem } from '../styles/Grid';
import yearRange from '../utils/yearRange';

const convertOffsetToTonnes = (value: number) => {
  // Carbon offset is counted in tonnes
  return value * 1000;
};

const getTotalFootprint = (carbonFootprint, globalCoefficients) => {
  if (!carbonFootprint) return 0;
  const {
    driving: {
      machineryAndEquipmentFootprint,
      ownedVehiclesFootprint,
      rentalVehiclesFootprint,
      taxiFootprint,
    },
    energy: { energyElectricityFootprint, energyHotWaterFootprint },
    flight: {
      domesticFlightsFootprint,
      internationalFlightsFootprint,
      icaoFootprint,
    },
    waste: {
      bulkyWasteFootprint,
      compostingFootprint,
      glassFootprint,
      incinerationFootprint,
      landfillFootprint,
      metalsFootprint,
      paperFootprint,
      plasticFootprint,
    },
  } = calculateEmission(carbonFootprint, globalCoefficients);

  return (
    machineryAndEquipmentFootprint +
    ownedVehiclesFootprint +
    rentalVehiclesFootprint +
    taxiFootprint +
    energyElectricityFootprint +
    energyHotWaterFootprint +
    domesticFlightsFootprint +
    internationalFlightsFootprint +
    icaoFootprint +
    bulkyWasteFootprint +
    compostingFootprint +
    glassFootprint +
    incinerationFootprint +
    landfillFootprint +
    metalsFootprint +
    paperFootprint +
    plasticFootprint
  );
};

const DashboardOverview = ({
  flattenedData,
  globalCoefficients,
  municipalityData,
}: {
  flattenedData: GetMunicipalityData_municipality_entities_carbonFootprintByYear[];
  globalCoefficients: FilteredGlobalCoefficients_globalCoefficients;
  municipalityData: GetMunicipalityData;
}) => {
  // Deep clone object because it is readonly
  const combinedDataByCategory: GetMunicipalityData_municipality_entities_carbonFootprintByYear =
    JSON.parse(JSON.stringify(flattenedData[0]));

  flattenedData.slice(1).forEach((carbonFootprint) => {
    for (let categoryKey of Object.keys(carbonFootprint)) {
      for (let [subCategoryKey, value] of Object.entries(
        carbonFootprint[categoryKey],
      )) {
        if (!value) return;

        if (typeof value === 'number')
          combinedDataByCategory[categoryKey][subCategoryKey] += value;
        else if (typeof value === 'object')
          for (let [key, value] of Object.entries(
            carbonFootprint[categoryKey][subCategoryKey],
          )) {
            combinedDataByCategory[categoryKey][subCategoryKey][key] += value;
          }
      }
    }
  });

  const {
    driving: {
      machineryAndEquipmentFootprint,
      ownedVehiclesFootprint,
      rentalVehiclesFootprint,
      taxiFootprint,
    },
    energy: { energyElectricityFootprint, energyHotWaterFootprint },
    flight: {
      domesticFlightsFootprint,
      internationalFlightsFootprint,
      icaoFootprint,
    },
    waste: {
      bulkyWasteFootprint,
      compostingFootprint,
      glassFootprint,
      incinerationFootprint,
      landfillFootprint,
      metalsFootprint,
      paperFootprint,
      plasticFootprint,
    },
    carbonOffset,
  } = calculateEmission(combinedDataByCategory, globalCoefficients);

  const drivingEmission =
    machineryAndEquipmentFootprint +
    ownedVehiclesFootprint +
    rentalVehiclesFootprint +
    taxiFootprint;
  const energyEmission = energyElectricityFootprint + energyHotWaterFootprint;
  const flightEmission =
    icaoFootprint + domesticFlightsFootprint + internationalFlightsFootprint;
  const wasteEmission =
    bulkyWasteFootprint +
    compostingFootprint +
    glassFootprint +
    incinerationFootprint +
    landfillFootprint +
    metalsFootprint +
    paperFootprint +
    plasticFootprint;

  const emissionSum =
    drivingEmission + energyEmission + flightEmission + wasteEmission;
  const offsetSum = convertOffsetToTonnes(carbonOffset.quantity);
  const emissionDifference = emissionSum - offsetSum;

  const colors = ['#0077b6', '#14746f', '#a4133c', '#ffb700', '#036666'];

  const results = [
    { name: 'Eigin bílar', value: ownedVehiclesFootprint, color: '#023e8a' },
    {
      name: 'Bílaleigubílar',
      value: rentalVehiclesFootprint,
      color: '#0077b6',
    },
    { name: 'Leigubílar', value: taxiFootprint, color: '#0096c7' },
    {
      name: 'Vinnuvélar og tæki',
      value: machineryAndEquipmentFootprint,
      color: '#00b4d8',
    },

    {
      name: 'Rafmagn',
      value: energyElectricityFootprint,
      color: '#14746f',
    },
    {
      name: 'Heitt vatn',
      value: energyHotWaterFootprint,
      color: '#248277',
    },

    { name: 'ICAO', value: icaoFootprint, color: '#800f2f' },
    {
      name: 'Innanlandsflug',
      value: domesticFlightsFootprint,
      color: '#a4133c',
    },
    {
      name: 'Millilandaflug',
      value: internationalFlightsFootprint,
      color: '#c9184a',
    },

    { name: 'Urðun', value: landfillFootprint, color: '#ff8800' },
    { name: 'Jarðgerð', value: compostingFootprint, color: '#ff9500' },
    { name: 'Brennsla', value: incinerationFootprint, color: '#ffa200' },
    { name: 'Plast', value: plasticFootprint, color: '#ffb700' },
    { name: 'Pappír/pappi', value: paperFootprint, color: '#ff8800' },
    { name: 'Gler', value: glassFootprint, color: '#ff9500' },
    { name: 'Málmar', value: metalsFootprint, color: '#ffa200' },
    { name: 'Grófur úrgangur', value: bulkyWasteFootprint, color: '#ffb700' },
  ];

  const resultsByCategory = [
    {
      name: 'Akstur',
      value: drivingEmission,
      color: '#03045e',
    },
    {
      name: 'Orka',
      value: energyEmission,
      color: '#036666',
    },

    {
      name: 'Flug',
      value: flightEmission,
      color: '#590d22',
    },

    {
      name: 'Úrgangur',
      value: wasteEmission,
      color: '#ff7b00',
    },
  ];

  const entities = municipalityData.municipality.entities.map(
    ({ name, carbonFootprintByYear }, index) => ({
      name,
      value: getTotalFootprint(carbonFootprintByYear[0], globalCoefficients),
      color: colors[index % colors.length],
    }),
  );

  if (!entities.some((entity) => entity.value > 0)) {
    return <NotEnoughData />;
  }

  return (
    <>
      <Section heading="Yfirlit" columns={3}>
        <KPI title="Losun" value={emissionSum} />
        <KPI title="Kolefnisjöfnun" value={offsetSum} />
        <KPI
          title="Losun - kolefnisjöfnun"
          value={emissionDifference}
          numberColors
        />
      </Section>
      <Section heading="Flokkar" columns={4}>
        <GridItem $gridColumn="full">
          <OverviewPieChart
            mainCategoryName="Flokkur"
            data={results}
            dataByCategory={resultsByCategory}
            showLegend
          />
        </GridItem>
      </Section>
      <Section heading="Rekstrareiningar">
        <GridItem $gridColumn="full">
          <OverviewPieChart
            mainCategoryName="Rekstrareining"
            data={entities}
            showLegend
          />
        </GridItem>
      </Section>
    </>
  );
};

export default function Dashboard() {
  const { municipality } = useUser();

  const currentYear = new Date().getFullYear();
  const years = [...yearRange(currentYear, 2010)];

  const [selectedYear, setSelectedYear] = useState(currentYear);

  const municipalityData = useQuery<
    GetMunicipalityData,
    GetMunicipalityDataVariables
  >(GET_DASHBOARD_DATA_QUERY, {
    variables: {
      where: { id: municipality.id },
      carbonFootprintWhere: {
        year: { equals: selectedYear },
        completed: { equals: true },
      },
    },
  });

  const flattenFootprintData = (data: GetMunicipalityData) =>
    data.municipality.entities.flatMap(
      (entity) => entity.carbonFootprintByYear,
    );

  const refreshDataOnSubmit = useCallback(
    (footprintId: number) => {
      // Fetch only brand-new submitted footprints, if it existed before apollo caching will update state
      if (
        !flattenFootprintData(municipalityData.data).some(
          (footprint) => footprint.id === footprintId,
        )
      ) {
        municipalityData.refetch();
      }
    },
    [municipalityData],
  );

  const coefficients = useQuery<
    FilteredGlobalCoefficients,
    FilteredGlobalCoefficientsVariables
  >(FILTERED_GLOBAL_COEFFICIENTS_QUERY, {
    variables: { where: { year: { equals: selectedYear } } },
  });

  let isEnoughDataToDisplay = true;

  if (!municipalityData.data) isEnoughDataToDisplay = false;

  const flattenedData = isEnoughDataToDisplay
    ? flattenFootprintData(municipalityData.data)
    : [];

  isEnoughDataToDisplay = flattenedData.length > 0;

  return (
    <>
      <Box
        as="header"
        $display="flex"
        $alignItems="flex-start"
        $justifyContent="space-between"
        $flex={0}
      >
        <Box $display="flex" $alignItems="center">
          <SelectMunicipality />
          <Select
            title="Tímabil"
            options={years.map((year) => ({
              label: `${year}`,
              value: year,
            }))}
            onChange={(year) => setSelectedYear(Number(year.value))}
            renderLabel={(label) => (
              <Box $display="flex" $alignItems="center">
                <Text>{label}</Text>{' '}
              </Box>
            )}
          />
        </Box>

        <Box
          $display="grid"
          $alignItems="center"
          $gridTemplateColumns="1fr 1fr"
          $gridColumnGap={2}
        >
          <Modal
            baseId="dashboard-modal"
            disclosure={
              <Button variant="primary" fillWidth>
                Skrá gögn
              </Button>
            }
          >
            {({ modal }) => (
              <DataModal modal={modal} onSubmit={refreshDataOnSubmit} />
            )}
          </Modal>
          <Modal
            baseId="dashboard-modal"
            disclosure={
              <Button variant="secondary" fillWidth>
                Skila til UST
              </Button>
            }
          >
            {({ modal }) => <DocumentsModal modal={modal} />}
          </Modal>
        </Box>
      </Box>
      {isEnoughDataToDisplay ? (
        <DashboardOverview
          flattenedData={flattenedData}
          municipalityData={municipalityData.data}
          globalCoefficients={coefficients.data.globalCoefficients[0]}
        />
      ) : (
        <NotEnoughData />
      )}
    </>
  );
}
