import { useUser } from '../../context/user';
import { Box } from '../../styles/Box';
import Select from '../Select/Select';
import { Text } from '../Text';

export default function SelectMunicipality() {
  const { managesMunicipalities, municipality, setMunicipality } = useUser();

  return (
    <Select
      title="Sveitarfélag"
      initialSelectedIndex={managesMunicipalities.findIndex(
        (managedMunicipality) => managedMunicipality.name === municipality.name,
      )}
      options={managesMunicipalities.map((municipality) => ({
        label: `${municipality.name}`,
        value: municipality.name,
      }))}
      onChange={(municipality) => {
        const found = managesMunicipalities.find(
          (m) => m.name === municipality.value,
        );
        if (found) {
          setMunicipality({ id: found.id, name: found.name });
        } else {
          console.error(
            `Could not find managed municipality with the name '${municipality.value}'`,
          );
        }
      }}
      renderLabel={(label) => (
        <Box $display="flex" $alignItems="center">
          <Text>{label}</Text>
        </Box>
      )}
    />
  );
}
