import { InputHTMLAttributes, useRef } from 'react';
import { LabelText, Small } from '../../components/Text';
import * as styles from './Input.styles';
import { useToggle } from 'react-use';

interface InputProps extends InputHTMLAttributes<HTMLTextAreaElement> {
  label: string;
  sublabel?: string;
}

export default function TextArea({
  id,
  label,
  value,
  sublabel,
  onBlur = () => {},
  ...rest
}: InputProps) {
  const [focused, toggleFocus] = useToggle(false);
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const handleBlur = (e) => {
    toggleFocus();
    onBlur(e);
  };

  const handleFocus = () => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };

  return (
    <styles.root>
      <LabelText
        as="label"
        color="black80"
        htmlFor={id}
        paddingBottom={2}
        onClick={handleFocus}
      >
        {label}
      </LabelText>
      {sublabel && (
        <Small fontWeight={400} fontStyle="italic" color="gray100">
          {sublabel}
        </Small>
      )}
      <styles.textAreaWrap focused={focused}>
        <styles.textArea
          value={value}
          id={id}
          ref={inputRef}
          onFocus={toggleFocus}
          onBlur={handleBlur}
          {...rest}
        />
      </styles.textAreaWrap>
    </styles.root>
  );
}
