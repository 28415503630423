import { gql, useMutation } from '@apollo/client';
import React from 'react';
import { DialogStateReturn } from 'reakit/ts';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import { layout } from '../components/Modal';
import { H2, H3 } from '../components/Text';
import { Box } from '../styles/Box';
import {
  UpsertUserWithAccessToPrincipality,
  UpsertUserWithAccessToPrincipalityVariables,
} from './__generated__/UpsertUserWithAccessToPrincipality';
import { useUser } from '../context/user';
import KennitalaInput from '../components/Input/KennitalaInput';

// Cannot import enum from globalTypes

export enum Role {
  ADMIN = 'ADMIN',
  MANAGER = 'MANAGER',
  USER = 'USER',
}

const UPSERT_USER = gql`
  mutation UpsertUserWithAccessToPrincipality(
    $where: UserWhereUniqueInput!
    $create: UserCreateInput!
    $update: UserUpdateInput!
  ) {
    upsertOneUser(where: $where, create: $create, update: $update) {
      kennitala
    }
  }
`;

type NewUserModalProps = {
  modal: DialogStateReturn;
  onCreated: () => void;
};

export default function NewUserModal({ modal, onCreated }: NewUserModalProps) {
  const [kennitala, setKennitala] = React.useState<string | null>(null);
  const [fullname, setFullname] = React.useState<string | null>(null);
  const [isAdmin, setIsAdmin] = React.useState<boolean>(false);

  const { municipality, role } = useUser();

  const [commit] = useMutation<
    UpsertUserWithAccessToPrincipality,
    UpsertUserWithAccessToPrincipalityVariables
  >(UPSERT_USER);

  const upsertUser = () =>
    commit({
      variables: {
        where: { kennitala },
        update: {
          managesMunicipalities: { connect: [{ id: municipality.id }] },
        },
        create: {
          kennitala,
          fullname,
          role: Role[isAdmin ? 'ADMIN' : 'MANAGER'],
          managesMunicipalities: { connect: [{ id: municipality.id }] },
        },
      },
    });
  return (
    <layout.modalOuter>
      <layout.inner>
        <layout.content>
          <layout.scrollable>
            <div>
              <Box $paddingTop={4} $paddingBottom={1}>
                <H2>Gefa aðgang að sveitarfélagi</H2>
                <H3 paddingY={2} color="black80" fontWeight={400}>
                  Gefur notanda aðgang að öllum rekstrareiningum í
                  sveitarfélaginu og að skila gögnum til Umhverfisstofnunar.
                </H3>
              </Box>

              <Box $paddingY={1} $display={'flex'}>
                <KennitalaInput
                  id="user.kennitala"
                  label="Kennitala notanda"
                  value={kennitala}
                  onValueChange={(value) => setKennitala(value.value)}
                  placeholder="______-____"
                />
              </Box>
              <Box $paddingY={1} $display={'flex'}>
                <Input
                  id="user.fullname"
                  type="string"
                  label="Nafn notanda"
                  value={fullname}
                  onChange={(e) => setFullname(e.target.value)}
                />
              </Box>
              {role === 'ADMIN' && (
                <Box $paddingY={1} $display={'flex'}>
                  <Input
                    label="Hefur aðgang að öllum sveitarfélögum og yfirferð skila"
                    type="checkbox"
                    checked={isAdmin}
                    onChange={(event) => setIsAdmin(event.target.checked)}
                  />
                </Box>
              )}
            </div>
          </layout.scrollable>
          <layout.bottom>
            <Box $paddingRight={2}>
              <Button variant="secondary" onClick={modal.hide}>
                Hætta við
              </Button>
            </Box>
            <Box $paddingLeft={2}>
              <Button
                variant="primary"
                onClick={async () => {
                  await upsertUser();
                  setKennitala('');
                  setFullname('');
                  setIsAdmin(false);
                  onCreated();
                  modal.hide();
                }}
              >
                Gefa aðgang
              </Button>
            </Box>
          </layout.bottom>
        </layout.content>
      </layout.inner>
    </layout.modalOuter>
  );
}
