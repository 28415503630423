import { gql } from '@apollo/client';

export const GET_ENTITIES_IN_MUNICIPALITY = gql`
  query GetEntitiesInMunicipality($where: MunicipalityWhereUniqueInput!) {
    municipality(where: $where) {
      id
      name
      entities {
        id
        updatedAt
        name
        carbonFootprintByYear {
          id
          year
          completed
          isVerified
        }
      }
    }
  }
`;
