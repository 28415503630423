import React, { useState } from 'react';
import Input from '../Input'
import Button from '../Button/Button';

export default function DocumentUpload({
  value,
  label,
  municipalityId,
  typeName,
  year,
  onChange,
  ...rest
}) {
  const [loading, setLoading] = useState(false);
  const onUpload = async (event) => {
    const file = event.target.files[0];
    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('municipalityId', municipalityId);
    formData.append('typeName', typeName);
    formData.append('year', year);

    const result = await fetch('/document', {
      method: 'POST',
      body: formData,
    });
    const document = await result.json();
    console.log(document)
    onChange(document.url);
    setLoading(false);
  }

  return (
    <>
      {value ?
        <div style={{display: 'flex', alignItems: 'center'}}>
          <Input label={label} type="text" value={loading ? 'Loading...' : value.substr(value.lastIndexOf('/') + 1)} />
          <Button onClick={() => onChange(undefined)}>Breyta skrá</Button>
        </div>
        :
        <Input type="file" label={label} onChange={onUpload} {...rest}/>
      }
    </>
  );
}
