import { ReactNode } from 'react';
import * as styles from './Table.styles';

type Props = {
  children: ReactNode;
  horizontalPadding?: boolean;
  centered?: boolean;
  backgroundColor?: string;
};

export default function Table({
  children,
  horizontalPadding,
  centered,
  backgroundColor = 'transparent',
}: Props) {
  return (
    <styles.wrap
      horizontalPadding={horizontalPadding}
      backgroundColor={backgroundColor}
    >
      <table className={centered && 'centered'}>{children}</table>
    </styles.wrap>
  );
}
