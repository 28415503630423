import { FunctionComponent } from 'react';
import OverviewPieChart from '../../charts/OverviewPieChart';
import Table from '../../components/Table';
import { H2, Text } from '../../components/Text';
import { Box } from '../../styles/Box';
import { GridContainer, GridItem } from '../../styles/Grid';
import { ModalDataComponentProps } from '../../types';
import { calculateEmission } from '../../utils/calculateEmission';
import { formatNumber } from '../../utils/formatting';
import styled from 'styled-components';
import KPI from '../../components/KPI';
import Section from '../../components/Section';

export const ColorBox = styled('div')(({ color }) => ({
  backgroundColor: color,
  height: 12,
  width: 12,
  marginRight: 12,
}));

const Overview: FunctionComponent<Omit<ModalDataComponentProps, 'onChange'>> =
  ({ state, globalCoefficients }) => {
    if (!state || !globalCoefficients) {
      return null;
    }

    const {
      driving: {
        ownedVehiclesFootprint,
        rentalVehiclesFootprint,
        taxiFootprint,
        machineryAndEquipmentFootprint,
      },
      energy: { energyElectricityFootprint, energyHotWaterFootprint },
      flight: {
        icaoFootprint,
        domesticFlightsFootprint,
        internationalFlightsFootprint,
      },
      waste: {
        bulkyWasteFootprint,
        compostingFootprint,
        glassFootprint,
        incinerationFootprint,
        landfillFootprint,
        metalsFootprint,
        paperFootprint,
        plasticFootprint,
      },
    } = calculateEmission(state, globalCoefficients);

    const results = [
      { name: 'Eigin bílar', value: ownedVehiclesFootprint, color: '#023e8a' },
      {
        name: 'Bílaleigubílar',
        value: rentalVehiclesFootprint,
        color: '#0077b6',
      },
      { name: 'Leigubílar', value: taxiFootprint, color: '#0096c7' },
      {
        name: 'Vinnuvélar og tæki',
        value: machineryAndEquipmentFootprint,
        color: '#00b4d8',
      },

      {
        name: 'Rafmagn',
        value: energyElectricityFootprint,
        color: '#14746f',
      },
      {
        name: 'Heitt vatn',
        value: energyHotWaterFootprint,
        color: '#248277',
      },

      { name: 'ICAO', value: icaoFootprint, color: '#800f2f' },
      {
        name: 'Innanlandsflug',
        value: icaoFootprint > 0 ? 0 : domesticFlightsFootprint,
        color: '#a4133c',
      },
      {
        name: 'Millilandaflug',
        value: icaoFootprint > 0 ? 0 : internationalFlightsFootprint,
        color: '#c9184a',
      },

      { name: 'Urðun', value: landfillFootprint, color: '#ff8800' },
      { name: 'Jarðgerð', value: compostingFootprint, color: '#ff9500' },
      { name: 'Brennsla', value: incinerationFootprint, color: '#ffa200' },
      { name: 'Plast', value: plasticFootprint, color: '#ffb700' },
      { name: 'Pappír/pappi', value: paperFootprint, color: '#ff8800' },
      { name: 'Gler', value: glassFootprint, color: '#ff9500' },
      { name: 'Málmar', value: metalsFootprint, color: '#ffa200' },
      { name: 'Grófur úrgangur', value: bulkyWasteFootprint, color: '#ffb700' },
    ];

    const resultsByCategory = [
      {
        name: 'Akstur',
        value:
          ownedVehiclesFootprint +
          rentalVehiclesFootprint +
          taxiFootprint +
          machineryAndEquipmentFootprint,
        color: '#03045e',
      },
      {
        name: 'Orka',
        value: energyElectricityFootprint + energyHotWaterFootprint,
        color: '#036666',
      },

      {
        name: 'Flug',
        value:
          icaoFootprint > 0
            ? icaoFootprint
            : domesticFlightsFootprint + internationalFlightsFootprint,
        color: '#590d22',
      },

      {
        name: 'Úrgangur',
        value:
          landfillFootprint +
          compostingFootprint +
          incinerationFootprint +
          plasticFootprint +
          paperFootprint +
          glassFootprint +
          metalsFootprint +
          bulkyWasteFootprint,
        color: '#ff7b00',
      },
    ];

    const total = resultsByCategory.reduce((acc, { value }) => acc + value, 0);

    return (
      <div>
        <Box $paddingY={4}>
          <H2 pb={2}>
            {state.entity.name} ({state.year})
          </H2>
          <Text>
            Niðurstaða fyrir {state.entity.name} árið {state.year}.
          </Text>
        </Box>
        <GridContainer $gridTemplateColumns="1fr 1fr" $minHeight={250} $my={4}>
          <GridItem>
            <OverviewPieChart
              data={results}
              dataByCategory={resultsByCategory}
            />
          </GridItem>
          <GridItem>
            <Table>
              <thead>
                <tr>
                  <th>Flokkur</th>
                  <th>Losun (kg CO₂)</th>
                  <th>% af heild</th>
                </tr>
              </thead>
              <tbody>
                {resultsByCategory.map(({ name, value, color }) => (
                  <tr key={name}>
                    <td>
                      <Box $display="flex" $alignItems="center">
                        <ColorBox color={color} /> {name}
                      </Box>
                    </td>
                    <td>{formatNumber(value)}</td>
                    <td>{formatNumber((value / total) * 100, 2)}%</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </GridItem>
          <GridItem $gridColumn="span 3">
            <Section columns={3}>
              <KPI title="Heildarlosun" value={total} />
              <KPI
                title="Kolefnisjöfnun"
                value={state.carbonOffset.quantity * 1000}
              />
              <KPI
                title="Losun - Kolefnisjöfnun"
                value={total - state.carbonOffset.quantity * 1000}
                numberColors
              />
            </Section>
          </GridItem>
        </GridContainer>
      </div>
    );
  };

export default Overview;
