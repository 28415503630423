import styled from 'styled-components';
import {
  space,
  color,
  SpaceProps,
  LayoutProps,
  ColorProps,
  GridProps,
  flex,
  FlexProps,
  variant,
  FlexboxProps,
  flexbox,
  position,
  PositionProps,
  border,
  BorderProps,
  borderBottom,
  BorderBottomProps,
  textAlign,
  TextAlignProps,
} from 'styled-system';
import { layout } from '../layout';
import { grid } from '../gridLayout';
import { transient, Transient } from '../../utils/transient';
import theme from '../../utils/theme';
import { focusable } from '../customFocusStyle';
import { media } from '../../utils/media';

export type BoxVariants = 'card' | 'hoverCard';

type TransientLayoutProps = Transient<LayoutProps>;
type TransientColorProps = Transient<ColorProps>;
type TransientBorderProps = Transient<BorderProps>;
type TransientBorderBottomProps = Transient<BorderBottomProps>;
type TransientSpaceProps = Transient<SpaceProps>;
type TransientGridProps = Transient<GridProps>;
type TransientFlexProps = Transient<FlexProps>;
type TransientPositionProps = Transient<PositionProps>;
type TransientFlexboxProps = Transient<FlexboxProps>;
type TransientTextAlignProps = Transient<TextAlignProps>;

export type BoxProps = TransientLayoutProps &
  TransientColorProps &
  TransientBorderProps &
  TransientBorderBottomProps &
  TransientSpaceProps &
  TransientGridProps &
  TransientFlexProps &
  TransientPositionProps &
  TransientFlexboxProps &
  TransientTextAlignProps & {
    variant?: BoxVariants;
    as?: React.ElementType;
    bleedBackgroundColor?: keyof typeof theme.colors;
    $focusable?: boolean;
  };

export const Box = styled('div')<BoxProps>(
  transient(grid),
  transient(flex),
  transient(position),
  transient(border),
  transient(borderBottom),
  transient(space),
  transient(color),
  transient(layout),
  transient(flexbox),
  transient(textAlign),
  transient(textAlign),
  variant({
    variants: {
      card: {
        backgroundColor: 'white',
        borderRadius: 'card',
        overflow: 'hidden',
      },
      hoverCard: {
        backgroundColor: 'transparent',
        [media['md']]: {
          '&:hover, &:focus-within': {
            backgroundColor: 'neutral',
            boxShadow: 'large',
            cursor: 'pointer',
          },
          '&:focus-within a': {
            textDecoration: 'underline',
            '&:focus': {
              outline: 'none',
            },
          },
        },
      },
    },
  }),
  focusable,
);
