import * as styles from './Input.styles';
import { useToggle } from 'react-use';
import { NumericFormat,
  NumericFormatProps,
} from 'react-number-format';
import React from 'react';

interface InputProps extends Omit<NumericFormatProps, 'onBlur' | 'value'> {
  label: string;
  onBlur: (value: number) => void;
  value: number;
}

export const NoLabelInput = ({ onBlur, value, id, ...rest }: InputProps) => {
  const [focused, toggleFocus] = useToggle(false);
  const [inputValue, setInputValue] =
    React.useState<string>(value.toString());

  const handleBlur = React.useCallback(() => {
    const newValue = parseFloat(inputValue);
    if (value !== newValue) {
      toggleFocus();
      onBlur(newValue);
    }
  }, [inputValue, onBlur, toggleFocus, value]);

  return (
    <styles.circleInputWrap focused={focused} onBlur={handleBlur}>
      <NumericFormat
        id={id}
        value={inputValue}
        onValueChange={({ value }) => {
          setInputValue(value)
        }}
        inputMode="decimal"
        thousandSeparator="."
        decimalSeparator=","
        customInput={styles.circleInput}
      />
    </styles.circleInputWrap>
  );
};
