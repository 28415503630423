import styled from 'styled-components';
import theme from '../../utils/theme';

export const wrap = styled('div')<{
  horizontalPadding?: boolean;
  backgroundColor: string;
}>(({ horizontalPadding, backgroundColor }) => ({
  gridColumn: '1/-1',
  background: backgroundColor,
  paddingTop: theme.space[3],
  paddingBottom: theme.space[3],
  paddingLeft: horizontalPadding ? theme.space[3] : null,
  paddingRight: horizontalPadding ? theme.space[3] : null,
  borderRadius: theme.borderRadius.medium,
  overflowY: 'auto',
}));
