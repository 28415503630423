import styled from 'styled-components';

export const root = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
});

export const content = styled('div')({
  display: 'flex',
});
