import { gql, useMutation, useQuery } from '@apollo/client';
import { useCallback, useEffect, useState } from 'react';
import { DialogStateReturn } from 'reakit/ts';
import Button from '../components/Button/Button';
import Input from '../components/Input';
import DocumentUpload from '../components/DocumentUpload';
import { layout } from '../components/Modal';
import Select from '../components/Select';
import { H2, H3, Text } from '../components/Text';
import { Box } from '../styles/Box';
import { GridContainer, GridItem } from '../styles/Grid';
import {
  GetDocuments,
  GetDocumentsVariables,
} from './__generated__/GetDocuments';
import {
  UpsertDocuments,
  UpsertDocumentsVariables,
} from './__generated__/UpsertDocuments';
import { useUser } from '../context/user';
import yearRange from '../utils/yearRange';

const DOCUMENTS_FRAGMENT = gql`
  fragment DocumentsModal_DocumentCollection on DocumentCollection {
    id
    year
    name
    email
    climatePolicy
    climateGoal
    actionPlan
    population
    checklist
    confirmed
  }
`;

const GET_DOCUMENTS_QUERY = gql`
  ${DOCUMENTS_FRAGMENT}
  query GetDocuments($where: DocumentCollectionWhereUniqueInput!) {
    documentCollection(where: $where) {
      ...DocumentsModal_DocumentCollection
    }
  }
`;

const UPSERT_DOCUMENTS_MUTATION = gql`
  ${DOCUMENTS_FRAGMENT}
  mutation UpsertDocuments(
    $where: DocumentCollectionWhereUniqueInput!
    $create: DocumentCollectionCreateInput!
    $update: DocumentCollectionUpdateInput!
  ) {
    upsertOneDocumentCollection(
      where: $where
      create: $create
      update: $update
    ) {
      ...DocumentsModal_DocumentCollection
    }
  }
`;

export default function DocumentsModal({
  modal,
}: {
  modal: DialogStateReturn;
}) {
  const { municipality } = useUser();

  const currentYear = new Date().getFullYear();
  const years = [...yearRange(currentYear, 2010)];

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [selectedYear, setSelectedYear] = useState(currentYear);

  const { data } = useQuery<GetDocuments, GetDocumentsVariables>(
    GET_DOCUMENTS_QUERY,
    {
      variables: {
        where: {
          municipalityId_year: {
            municipalityId: municipality.id,
            year: selectedYear,
          },
        },
      },
    },
  );

  const [population, setPopulation] = useState(
    data?.documentCollection?.population,
  );

  const [climatePolicy, setClimatePolicy] = useState(
    data?.documentCollection?.climatePolicy,
  );

  const [climateGoal, setClimateGoal] = useState(
    data?.documentCollection?.climateGoal,
  );

  const [actionPlan, setActionPlan] = useState(
    data?.documentCollection?.actionPlan,
  );

  const [checklist, setChecklist] = useState(
    data?.documentCollection?.checklist,
  );

  useEffect(() => {
    if (data?.documentCollection) {
      const { name, email, climatePolicy, climateGoal, actionPlan, population, checklist } =
        data.documentCollection;
      setName(name);
      setEmail(email);
      setClimatePolicy(climatePolicy || '');
      setClimateGoal(climateGoal || '');
      setActionPlan(actionPlan || '');
      setPopulation(population);
      setChecklist(checklist);
    } else {
      setName('');
      setEmail('');
      setClimatePolicy('');
      setClimateGoal('');
      setActionPlan('');
      setPopulation(null);
      setChecklist('')
    }
  }, [data]);

  const [upsertDocuments, response] = useMutation<
    UpsertDocuments,
    UpsertDocumentsVariables
  >(UPSERT_DOCUMENTS_MUTATION);

  useEffect(() => {
    if (response?.data) {
      const { name, email, climatePolicy, climateGoal, actionPlan, population, checklist } =
        response.data.upsertOneDocumentCollection;
      setName(name);
      setEmail(email);
      setClimatePolicy(climatePolicy);
      setClimateGoal(climateGoal);
      setActionPlan(actionPlan);
      setPopulation(population);
      setChecklist(checklist)
    }
  }, [response?.data]);

  const handleSubmit = useCallback(async () => {
    const { data } = await upsertDocuments({
      variables: {
        where: {
          municipalityId_year: {
            municipalityId: municipality.id,
            year: selectedYear,
          },
        },
        create: {
          name,
          email,
          year: selectedYear,
          municipality: { connect: { id: municipality.id } },
          climatePolicy,
          climateGoal,
          actionPlan,
          population,
          checklist,
        },
        update: {
          name: { set: name },
          email: { set: email },
          climatePolicy: { set: climatePolicy },
          climateGoal: { set: climateGoal },
          actionPlan: { set: actionPlan },
          population: { set: population },
          checklist: { set: checklist },
          confirmed: { set: false },
        },
      },
    });

    const documentsId = data.upsertOneDocumentCollection.id;

    fetch('/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ documentsId }),
    });
  }, [
    name,
    email,
    upsertDocuments,
    municipality.id,
    selectedYear,
    climatePolicy,
    climateGoal,
    actionPlan,
    population,
    checklist,
  ]);

  return (
    <layout.modalOuter>
      <layout.inner>
        <layout.content>
          <Box $display="flex" $flexDirection="column" $height="full">
            <Box $paddingTop={4} $paddingBottom={1}>
              <H2>Skila gögnum til Umhverfisstofnunar</H2>
              <H3 paddingY={2} color="black80" fontWeight={400}>
                Losunartölur rekstrareininga verður skilað til Umhverfisstofnunar og gögnin fyrir það ár verða læst.
              </H3>
              <p>Athugið að til þess að geta staðfest skilin þarf að setja inn öll umbeðin skjöl. Ef sum gögn (stefnan, markmiðasetning og aðgerðaáætlun) eru í einu skjali má hlaða sama skjalinu inn í viðeigandi reiti.</p>
              <p>Athugið að skýrt útfylltur gátlisti tryggir góða upplýsingagjöf til Umhverfis- og auðlindaráðuneytisins.</p>
            </Box>
            <layout.scrollable>
              <GridContainer
                $gridTemplateColumns="repeat(6, 1fr)"
                $paddingY={2}
              >
                <GridItem $gridColumn="span 2">
                  <Select
                    title="Tímabil"
                    options={years.map((year) => ({
                      label: `${year}`,
                      value: year,
                    }))}
                    onChange={(year) => setSelectedYear(Number(year.value))}
                    renderLabel={(label) => (
                      <div
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <Text>{label}</Text>{' '}
                      </div>
                    )}
                  />
                </GridItem>
              </GridContainer>
              <GridContainer $gridTemplateColumns={{ md: '1fr' }}>
                <GridItem>
                  <Input
                    id="nafn"
                    label="Nafn ábyrgðaraðila"
                    value={name}
                    onChange={(event) => {
                      setName(event.target.value);
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    id="netfang"
                    label="Netfang ábyrgðaraðila"
                    value={email}
                    onChange={(event) => {
                      setEmail(event.target.value);
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Input
                    id="íbúafjöldi"
                    type="number"
                    label="Fjöldi íbúa"
                    placeholder="0"
                    value={population}
                    onChange={(event) => {
                      setPopulation(parseInt(event.target.value));
                    }}
                  />
                </GridItem>
                <GridItem>
                  <DocumentUpload
                    id="loftslagsstefna"
                    label="Loftslagstefna"
                    typeName="climate-policy"
                    year={selectedYear}
                    municipalityId={municipality.id}
                    value={climatePolicy}
                    onChange={(url) => setClimatePolicy(url)}
                  />
                </GridItem>
                <GridItem>
                  <DocumentUpload
                    id="markmiðasetning"
                    label="Markmiðasetning"
                    typeName="climate-goal"
                    year={selectedYear}
                    municipalityId={municipality.id}
                    value={climateGoal}
                    onChange={(url) => setClimateGoal(url)}
                  />
                </GridItem>
                <GridItem>
                  <DocumentUpload
                    id="aðgerðaáætlun"
                    label="Aðgerðaáætlun"
                    typeName="action-plan"
                    year={selectedYear}
                    municipalityId={municipality.id}
                    value={actionPlan}
                    onChange={(url) => setActionPlan(url)}
                  />
                </GridItem>
                <GridItem>
                  <DocumentUpload
                    id="gátlisti"
                    label="Gátlisti"
                    typeName="checklist"
                    year={selectedYear}
                    municipalityId={municipality.id}
                    value={checklist}
                    sublabel={
                      <a href={`${process.env.PUBLIC_URL}/gatlisti.docx`}>
                        Smelltu hér til að sækja gátlista
                      </a>
                    }
                    onChange={(url) => setChecklist(url)}
                  />
                </GridItem>
              </GridContainer>
            </layout.scrollable>
            <GridContainer $paddingY={4} $mt="auto">
              <GridItem $gridColumn="3 / span 1">
                <Button
                  variant="secondary"
                  onClick={() => modal.hide()}
                  fillWidth
                >
                  Loka glugga
                </Button>
              </GridItem>
              <GridItem $gridColumn="4 / span 1">
                <Button
                  variant="primary"
                  onClick={() => {
                    handleSubmit();
                    modal.hide();
                  }}
                  fillWidth
                >
                  Staðfesta skil
                </Button>
              </GridItem>
            </GridContainer>
          </Box>
        </layout.content>
      </layout.inner>
    </layout.modalOuter>
  );
}
