import { useState } from 'react';
import { useMenuState, MenuItem } from 'reakit/Menu';
import theme from '../../utils/theme';
import Icon from '../Icon';
import { Text } from '../Text';
import * as styles from './Select.styles';

export type Option = {
  label: string;
  value: number | string;
  status?: 'ok' | 'in-progress';
};

type Props = {
  title?: string;
  options: Option[];
  onChange?: (option: Option) => void;
  renderLabel?: (label: string) => JSX.Element;
  initialSelectedIndex?: number;
};

export default function Select({
  title,
  options,
  onChange,
  renderLabel,
  initialSelectedIndex = 0,
}: Props) {
  const menu = useMenuState({ unstable_fixed: true });
  const [selected, set] = useState<Option>(options[initialSelectedIndex]);

  const onClick = (option: Option) => {
    set(option);
    menu.hide();
    onChange(option);
  };

  const hasOptions = !!options.length;
  const hasMoreThanOneOption = hasOptions && options.length > 1;

  return (
    <styles.wrap>
      {hasOptions ? (
        <>
          <styles.button {...menu}>
            <styles.buttonWrap>
              {title && (
                <Text>
                  <styles.label>{title}:</styles.label>
                </Text>
              )}
              <styles.selection>
                {typeof renderLabel === 'function' ? (
                  renderLabel(selected.label)
                ) : (
                  <Text>{selected?.label}</Text>
                )}
              </styles.selection>
              {hasMoreThanOneOption && (
                <styles.iconWrap>
                  <Icon name="caret-down" color={theme.colors.gray60} />
                </styles.iconWrap>
              )}
            </styles.buttonWrap>
          </styles.button>
          <styles.menu {...menu} aria-label="Sýna">
            <styles.list>
              {options.map((option) => (
                <MenuItem
                  {...menu}
                  key={option.label}
                  onClick={() => onClick(option)}
                >
                  <styles.item>
                    <Text fontSize={16}>{option.label}</Text>
                  </styles.item>
                </MenuItem>
              ))}
            </styles.list>
          </styles.menu>
        </>
      ) : (
        <Text>Engin rekstrareining</Text>
      )}
    </styles.wrap>
  );
}
