import { useMutation, useQuery } from '@apollo/client';
import { NoLabelInput } from '../components/Input/NoLabelInput';
import Section from '../components/Section';
import Table from '../components/Table';
import {
  ALL_GLOBAL_COEFFICIENTS_QUERY,
  GLOBAL_COEFFICIENTS_MUTATION,
} from '../queries/globalCoefficients';
import {
  GlobalCoefficientsMutation,
  GlobalCoefficientsMutationVariables,
} from './__generated__/GlobalCoefficientsMutation';
import { GlobalCoefficientsQuery } from './__generated__/GlobalCoefficientsQuery';
import { Title } from '../components/Text';
import React from 'react';

const ownedCars = [
  { key: 'ownedVehiclesGasoline', label: 'Bensín (kg/lítrar)' },
  { key: 'ownedVehiclesDiesel', label: 'Dísill (kg/lítrar)' },
  { key: 'ownedVehiclesMetan', label: 'Metan (kg/m³)' },
  { key: 'ownedVehiclesElectricity', label: 'Rafmagn (kg/kWh)' },
];

const rentalCars = [
  { key: 'rentalVehiclesGasoline', label: 'Bensín (kg/lítrar)' },
  { key: 'rentalVehiclesDiesel', label: 'Dísill (kg/lítrar)' },
  { key: 'rentalVehiclesMetan', label: 'Metan (kg/m³)' },
  { key: 'rentalVehiclesElectricity', label: 'Rafmagn (kg/kWh)' },
];

const taxiCars = [
  { key: 'taxiGasoline', label: 'Bensín (kg/km)' },
  { key: 'taxiDiesel', label: 'Dísill (kg/km)' },
  { key: 'taxiMetan', label: 'Metan (kg/km)' },
  { key: 'taxiElectricity', label: 'Rafmagn (kg/km)' },
];

const machineryAndEquipment = [
  { key: 'machineryGasoline', label: 'Bensín (kg/lítrar)' },
  { key: 'machineryDiesel', label: 'Dísill (kg/lítrar)' },
  { key: 'machineryMetan', label: 'Metan (kg/m³)' },
  { key: 'machineryElectricity', label: 'Rafmagn (kg/kWh)' },
  { key: 'machineryKerosene', label: 'Steinolía (kg/lítrar)' },
];

const energy = [
  { key: 'energyElectricity', label: 'Rafmagn (kg/kWh)' },
  { key: 'energyHotWater', label: 'Heitt vatn (kg/m³)' },
];

const domesticFlight = [
  { key: 'flightAEY_RVK', label: 'AEY-RVK-AEY' },
  { key: 'flightEGS_RVK', label: 'EGS-RVK-EGS' },
  { key: 'flightVEY_RVK', label: 'VEY-RVK-VEY' },
  { key: 'flightHFN_RVK', label: 'HFN-RVK-HFN' },
  { key: 'flightIFJ_RVK', label: 'IFJ-RVK-IFJ' },
  { key: 'flightOther', label: 'Annað' },
];

const internationFlight = [
  { key: 'flightOceania_RVK', label: 'Eyjaálfa' },
  { key: 'flightContinentalEurope_RVK', label: 'Evrópa, meginland' },
  { key: 'flightScandinavia_RVK', label: 'Evrópa, Norðurlönd, Bretlandseyjar' },
  { key: 'flightNorthAmerica_RVK', label: 'N-Ameríka' },
  { key: 'flightSouthAmerica_RVK', label: 'S-Ameríka' },
  { key: 'flightAfrica_RVK', label: 'Afríka' },
  { key: 'flightAsia_RVK', label: 'Asía' },
];

const waste = [
  { key: 'wasteLandfill', label: 'Urðun' },
  { key: 'wasteComposting', label: 'Jarðgerð' },
  { key: 'wasteIncineration', label: 'Brennsla' },
  { key: 'wastePlastic', label: 'Plast' },
  { key: 'wastePaper', label: 'Pappír/pappi' },
  { key: 'wasteGlass', label: 'Gler' },
  { key: 'wasteMetal', label: 'Málmar' },
  { key: 'wasteBulky', label: 'Grófur úrgangur' },
];

const categories = [
  { title: 'Eigin Bílar', coefficients: ownedCars },
  { title: 'Bílaleigubílar', coefficients: rentalCars },
  { title: 'Leigubílar', coefficients: taxiCars },
  { title: 'Vinnuvélar og tæki', coefficients: machineryAndEquipment },
  { title: 'Orka', coefficients: energy },
  { title: 'Innanlandsflug', coefficients: domesticFlight },
  { title: 'Millilandaflug', coefficients: internationFlight },
  { title: 'Úrgangur', coefficients: waste },
];

const Input = ({
  oldValue,
  coefficientType,
  year,
  onBlur,
}: {
  oldValue: number;
  coefficientType: string;
  year: number;
  onBlur: (newValue: number) => void;
}) => <NoLabelInput label={''} value={oldValue} onBlur={onBlur} />;

export default function Coefficients() {
  const { data } = useQuery<GlobalCoefficientsQuery>(
    ALL_GLOBAL_COEFFICIENTS_QUERY,
    {
      fetchPolicy: 'network-only',
    }
  );

  const [commit] = useMutation<
    GlobalCoefficientsMutation,
    GlobalCoefficientsMutationVariables
  >(GLOBAL_COEFFICIENTS_MUTATION);

  if (!data) return null;

  return (
    <Section>
      <Table horizontalPadding>
        <thead>
          <tr>
            <th>Gildi per ár</th>
            {data.globalCoefficients.map(({ year }) => (
              <th key={year}>{year}</th>
            ))}
          </tr>
        </thead>

        {data.globalCoefficients.length > 0 ? (
          <tbody>
            {categories.map(({ title, coefficients }) => (
              <React.Fragment key={title}>
                <tr key={title}>
                  <td colSpan={data.globalCoefficients.length + 1}>
                    <Title>{title}</Title>
                  </td>
                </tr>
                {coefficients.map(({ key, label }) => (
                  <tr key={key + '_label'}>
                    <td>{label}</td>
                    {data.globalCoefficients.map((values) => {
                      const year = values.year;
                      const oldValue = values[key];
                      const coefficientType = key;
                      return (
                        <td
                          key={key + values.year}
                          style={{ textAlign: 'right' }}
                        >
                          <Input
                            oldValue={oldValue}
                            coefficientType={coefficientType}
                            year={year}
                            onBlur={(newValue) => {
                              if (newValue !== oldValue)
                                commit({
                                  variables: {
                                    where: {
                                      year,
                                    },
                                    data: {
                                      [coefficientType]: {
                                        set: newValue,
                                      },
                                    },
                                  },
                                });
                            }}
                          />
                        </td>
                      );
                    })}
                  </tr>
                ))}
              </React.Fragment>
            ))}
          </tbody>
        ) : null}
      </Table>
    </Section>
  );
}
