import React, { createContext, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import * as styles from './user.styles';

import gql from 'graphql-tag';
import { Municipality } from '../types';


interface User {
  role: 'USER' | 'ADMIN' | 'MANAGER';
  managesMunicipalities: Municipality[];
}

interface ContextValue extends User {
  userLoading?: boolean;
  municipality?: Municipality;
  setMunicipality: (municipality: Municipality) => void;
}

const initialState: ContextValue = {
  role: 'USER',
  managesMunicipalities: [],
  municipality: null,
  setMunicipality: (municipality: Municipality) => {},
};

export const UserContext = createContext<ContextValue>(initialState);
export const useUser = () => useContext(UserContext);

const GET_ME = gql`
  query Me {
    me {
      role
      managesMunicipalities(orderBy: { name: asc }) {
        id
        name
      }
    }
  }
`;

const redirectToLogin = () => {
  window.location.href =
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:5000/auth'
      : `https://umsjon.loftslagsstefna.is/auth`;
};

const UserProvider = ({ children }: { children: React.ReactNode }) => {
  // Check if we have an auth token and store it in local storage
  const query = new URLSearchParams(window.location.search);
  let token = query.get('token');
  if (token) {
    localStorage.setItem('token', token);
  } else {
    token = localStorage.getItem('token');
  }

  // Redirect to login if there's no auth token
  useEffect(() => {
    if (token) return;
    redirectToLogin();
  }, [token]);

  const onError = (error: Error) => {
    if (error.message.includes('Unauthorized')) {
      redirectToLogin();
    }
  };

  // Fetch user info
  const {
    loading: userLoading,
    data,
  }: {
    refetch: () => void;
    loading: boolean;
    data?: { me: User };
  } = useQuery(GET_ME, {
    fetchPolicy: 'network-only',
    onError,
  });

  const [user, setUser] = useState<User | undefined>(initialState);
  const [municipality, setMunicipality] = useState<Municipality | undefined>(
    undefined,
  );

  useEffect(() => {
    setUser(data?.me);
    setMunicipality(data?.me.managesMunicipalities[0]);
  }, [data]);

  if (!userLoading && token && !municipality) {
    return (
      <styles.message>
        Þú hefur ekki aðgang að neinu sveitarfélagi, vinsamlegast hafðu samband
        við Umhverfisstofnun.
      </styles.message>
    );
  }

  return (
    <UserContext.Provider
      value={{
        ...user,
        municipality,
        setMunicipality,
      }}
    >
      {userLoading ? <h1>Loading...</h1> : children}
    </UserContext.Provider>
  );
};
export default UserProvider;
