import { styleFn } from 'styled-system';
export const focusable: styleFn = ({ $focusable }) => {
  if (!$focusable) {
    return null;
  }
  return {
    outline: 0,
    '&:focus-visible': {
      //TODO
    },
  };
};
