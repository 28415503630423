import { FieldExplanation, H2, H3 } from '../../components/Text';
import { Box } from '../../styles/Box';
import { ModalDataComponent, UpdateFunction } from '../../types';
import { FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_energy } from '../../queries/__generated__/FindOrCreateCarbonFootprint';
import { GridContainer, GridItem } from '../../styles/Grid';
import TextArea from '../../components/Input/Textarea';
import NumberFormatInput from '../../components/Input/NumberFormatInput';
import NumberWithSeperator from '../../components/Text/NumberWithSeperator';

type Energy = FindOrCreateCarbonFootprint_upsertOneCarbonFootprint_energy;

type HandleChange<T> = <OnChange extends UpdateFunction>(
  onChange: OnChange,
) => <Type extends keyof T, Value extends T[Type]>(
  type: Type,
  value: Value,
) => void;

const createChangeHandler: HandleChange<Energy> = (onChange) => {
  return (type, value) => {
    return onChange((oldState) => {
      const newState = { ...oldState };
      newState.energy[type] = value;
      return newState;
    });
  };
};

const Electricity: ModalDataComponent = ({
  state,
  onChange,
  globalCoefficients,
}) => {
  const {
    energy: { electricity, hotWater, comment },
  } = state;

  const handleChange = createChangeHandler(onChange);

  const { energyElectricity, energyHotWater } = globalCoefficients;

  return (
    <div>
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Orka</H2>
        <H3 paddingY={2}>Rafmagn</H3>
        <FieldExplanation>
          Hér skal skrá árlega notkun á rafmagni. Losun gróðurhúsalofttegunda
          reiknast sjálfkrafa. Upplýsingar má finna í bókhaldi eða óska eftir
          yfirliti frá þjónustuaðilum. Losun gróðurhúsalofttegunda vegna
          rafmagnsframleiðslu er vegið meðaltal losunar frá orkuframleiðslu með
          jarðeldsneyti, vatnsafli og jarðvarma. Tilgangurinn með þessari
          skráningu er að auka meðvitund um orkunotkun og koma í veg fyrir sóun.
        </FieldExplanation>
      </Box>
      <GridContainer $gridTemplateColumns={{ md: '1fr' }}>
        <GridItem>
          <NumberFormatInput
            id="power.electricityKwh"
            label={`Rafmagn (kWh)`}
            sublabel={
              <NumberWithSeperator
                value={energyElectricity}
                suffix="kg CO₂ / kWh"
              />
            }
            placeholder={'kWh á ári'}
            value={electricity}
            onValueChange={(e) => handleChange('electricity', e.floatValue)}
          />
        </GridItem>
        <H3>Heitt vatn</H3>
        <FieldExplanation>
          Hér skal skrá árlega notkun á heitu vatni. Þar sem haldið er utan um
          alla losun gróðurhúsalofttegunda frá jarðvarma í einni tölu, þ.e.
          vegna framleiðslu bæði rafmagns og heits vatns, er losunarstuðull
          fyrir heitt vatn núll. Tilgangurinn með þessari skráningu er því að
          auka meðvitund um notkun og koma í veg fyrir sóun.
        </FieldExplanation>
        <GridItem>
          <NumberFormatInput
            id="power.waterM3"
            label={`Heitt vatn (m³)`}
            sublabel={
              <NumberWithSeperator
                value={energyHotWater}
                suffix="kg CO₂ / m³"
              />
            }
            placeholder={'Rúmmetrar á ári'}
            value={hotWater}
            onValueChange={(e) => handleChange('hotWater', e.floatValue)}
          />
        </GridItem>
        <GridItem>
          <TextArea
            id="power.comment"
            type="text"
            label="Athugasemd"
            value={comment}
            onChange={(e) => handleChange('comment', e.target.value)}
          />
        </GridItem>
      </GridContainer>
    </div>
  );
};

export const power = {
  Electricity,
};
