import theme from '../../utils/theme';
import { ButtonText } from '../Text';
import Icon from '../Icon';
import { Button as ButtonStyles, ButtonVariants } from './Button.styles';
import { IconProps } from '../Icon/Icon';
import { Box } from '../../styles/Box';

type ButtonProps = {
  as?: string;
  className?: string;
  children?: React.ReactNode;
  color?: keyof typeof theme.colors;
  href?: string;
  variant?: ButtonVariants;
  onClick?: () => void;
  fillWidth?: boolean;
  minWidth?: number;
  noMaxWidth?: boolean;
  childWrap?: React.ReactNode | 'none';
  icon?: IconProps['name'];
  iconSize?: number;
  iconPosition?: 'before' | 'after';
  alignment?: 'start' | 'end';
};

const Link: React.FC<{ href: string }> = ({ children, href }) => (
  <a href={href}>{children}</a>
);

const NoWrap: React.FC = ({ children }) => <>{children}</>;

function Button({
  className,
  variant = 'primary',
  as = 'button',
  color,
  onClick = () => {},
  href,
  fillWidth,
  children,
  childWrap = ButtonText,
  icon,
  iconSize = 14,
  iconPosition = 'before',
  alignment = 'start',
}: ButtonProps) {
  const Wrap: any = childWrap === 'none' ? NoWrap : childWrap;
  const Component = href ? (
    <Link href={href}>
      <ButtonStyles
        className={className}
        alignment={alignment}
        role="button"
        $width={fillWidth ? 'full' : undefined}
        as="a"
        variant={variant}
        color={color}
        onClick={onClick}
      >
        <Wrap>{children}</Wrap>
      </ButtonStyles>
    </Link>
  ) : (
    <ButtonStyles
      as={as as any}
      className={className}
      alignment={alignment}
      $width={fillWidth ? 'full' : undefined}
      variant={variant}
      color={color}
      onClick={onClick}
    >
      {icon && iconPosition === 'before' && (
        <Box $pr={1} $display="flex">
          <Icon name={icon} width={iconSize} height={iconSize} />
        </Box>
      )}
      {children && <ButtonText whiteSpace="nowrap">{children}</ButtonText>}
      {icon && iconPosition === 'after' && (
        <Box $pl={1} $display="flex">
          <Icon name={icon} width={iconSize} height={iconSize} />
        </Box>
      )}
    </ButtonStyles>
  );

  return Component;
}

export default Button;
