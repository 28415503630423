import {
  FindOrCreateCarbonFootprintVariables,
  FindOrCreateCarbonFootprint_upsertOneCarbonFootprint,
} from '../queries/__generated__/FindOrCreateCarbonFootprint';

export default function parseStateIntoUpdateObject(
  state: FindOrCreateCarbonFootprint_upsertOneCarbonFootprint,
): FindOrCreateCarbonFootprintVariables['update'] {
  const {
    drivingData: { ownedVehicles, rentalVehicles, machineryAndEquipment, taxi },
    energy,
    waste,
    flights: { icao, domesticFlights, internationalFlights },
    carbonOffset,
    entityMetadata,
  } = state;

  return {
    updatedAt: { set: new Date().toISOString() },
    drivingData: {
      update: {
        ownedVehicles: {
          update: {
            gasoline: { set: ownedVehicles.gasoline },
            diesel: { set: ownedVehicles.diesel },
            electricity: {
              set: ownedVehicles.electricity,
            },
            metan: { set: ownedVehicles.metan },
            comment: { set: ownedVehicles.comment },
          },
        },
        rentalVehicles: {
          update: {
            gasoline: {
              set: rentalVehicles.gasoline,
            },
            diesel: { set: rentalVehicles.diesel },
            electricity: {
              set: rentalVehicles.electricity,
            },
            metan: { set: rentalVehicles.metan },
            comment: { set: rentalVehicles.comment },
          },
        },
        machineryAndEquipment: {
          update: {
            gasoline: {
              set: machineryAndEquipment.gasoline,
            },
            diesel: {
              set: machineryAndEquipment.diesel,
            },
            electricity: {
              set: machineryAndEquipment.electricity,
            },
            metan: {
              set: machineryAndEquipment.metan,
            },
            kerosene: {
              set: machineryAndEquipment.kerosene,
            },
            comment: { set: machineryAndEquipment.comment },
          },
        },
        taxi: {
          update: {
            gasoline: { set: taxi.gasoline },
            diesel: { set: taxi.diesel },
            electricity: { set: taxi.electricity },
            metan: { set: taxi.metan },
            comment: { set: taxi.comment },
          },
        },
      },
    },
    energy: {
      update: {
        electricity: { set: energy.electricity },
        hotWater: { set: energy.hotWater },
        comment: { set: energy.comment },
      },
    },
    waste: {
      update: {
        landfill: { set: waste.landfill },
        composting: { set: waste.composting },
        incineration: { set: waste.incineration },
        plastic: { set: waste.plastic },
        paper: { set: waste.paper },
        glass: { set: waste.glass },
        metals: { set: waste.metals },
        bulky: { set: waste.bulky },
        comment: { set: waste.comment },
      },
    },
    flights: {
      update: {
        icao: {
          update: {
            total: { set: icao.total },
            comment: { set: icao.comment },
          },
        },
        domesticFlights: {
          update: {
            AEYtoRVKtoAEY: { set: domesticFlights.AEYtoRVKtoAEY },
            EGStoRVKtoEGS: { set: domesticFlights.EGStoRVKtoEGS },
            HFNtoRVKtoHFN: { set: domesticFlights.HFNtoRVKtoHFN },
            IFJtoRVKtoIFJ: { set: domesticFlights.IFJtoRVKtoIFJ },
            VEYtoRVKtoVEY: { set: domesticFlights.VEYtoRVKtoVEY },
            other: { set: domesticFlights.other },
            comment: { set: domesticFlights.comment },
          },
        },
        internationalFlights: {
          update: {
            africa: { set: internationalFlights.africa },
            asia: { set: internationalFlights.asia },
            continentalEurope: { set: internationalFlights.continentalEurope },
            northAmerica: { set: internationalFlights.northAmerica },
            oceania: { set: internationalFlights.oceania },
            scandinavia: { set: internationalFlights.scandinavia },
            southAmerica: { set: internationalFlights.southAmerica },
            comment: { set: internationalFlights.comment },
          },
        },
      },
    },
    carbonOffset: {
      update: {
        quantity: { set: carbonOffset.quantity },
        comment: { set: carbonOffset.comment },
      },
    },
    entityMetadata: {
      update: {
        propertyArea: { set: entityMetadata.propertyArea },
        positions: { set: entityMetadata.positions },
        clients: { set: entityMetadata.clients },
        comment: { set: entityMetadata.comment },
      },
    },
  };
}
