import styled from 'styled-components';
import { Menu, MenuButton } from 'reakit/Menu';
import theme from '../../utils/theme';

export const wrap = styled('div')({
  position: 'relative',
  zIndex: theme.zIndex.above3,
  '& + &': {
    marginLeft: theme.space[2],
  },
});

export const list = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  padding: theme.space[2],
  maxHeight: 300,
  overflowY: 'auto',
});

export const button = styled(MenuButton)({
  width: '100%',
});

export const menu = styled(Menu)({
  minWidth: 250,
  boxShadow: '2px 2px 6px rgba(0,0,0,0.1)',
  marginTop: theme.space[2],
  borderRadius: theme.borderRadius.small,
  background: theme.colors.gray5,
});

export const buttonWrap = styled('div')({
  padding: `${theme.space[1.5]}px ${theme.space[2]}px`,
  alignItems: 'center',
  display: 'flex',
  minHeight: theme.space[7],
  background: theme.colors.gray5,
  borderRadius: theme.borderRadius.large,
});

export const label = styled('span')({
  color: theme.colors.gray100,
  marginRight: theme.space[1],
});

export const selection = styled('span')({
  fontWeight: 600,
  marginRight: theme.space[1],
  textAlign: 'left',
  width: '100%',
});

export const iconWrap = styled('div')({
  position: 'relative',
  top: 2,
});

export const item = styled('div')({
  textAlign: 'left',
  padding: theme.space[1.5],
  lineHeight: 1.5,
  '&:hover': {
    color: theme.colors.blue,
  },
});
