import { useMemo, useState, useCallback, useEffect, useRef } from 'react';
import { DialogStateReturn } from 'reakit/ts';
import Button from '../components/Button/Button';
import { Box } from '../styles/Box';
import { layout } from '../components/Modal';
import { ModalSidebar } from '../components/Modal/ModalSidebar/ModalSidebar';
import { useStore } from '../state';
import { useMutation, useQuery } from '@apollo/client';
import { establishment } from './categories/establishment';
import { FIND_OR_CREATE_CARBON_FOOTPRING } from '../queries/findOrCreateCarbonFootprint';
import Overview from './screens/Overview';
import {
  FindOrCreateCarbonFootprint,
  FindOrCreateCarbonFootprintVariables,
  FindOrCreateCarbonFootprint_upsertOneCarbonFootprint,
} from '../queries/__generated__/FindOrCreateCarbonFootprint';
import parseStateIntoUpdateObject from '../utils/parseStateIntoUpdateObject';
import { FILTERED_GLOBAL_COEFFICIENTS_QUERY } from '../queries/globalCoefficients';
import {
  FilteredGlobalCoefficients,
  FilteredGlobalCoefficientsVariables,
} from '../queries/__generated__/FilteredGlobalCoefficients';
import { VERIFY_CARBON_FOOTPRINT } from '../queries/verifyCarbonFootprint';
import { useUser } from '../context/user';
import { waitFor } from '../utils/waitFor';
import { LabelText } from '../components/Text';

function emptyCarbonFootprint(
  year: number,
  entityId: number,
): FindOrCreateCarbonFootprintVariables['create'] {
  return {
    year,
    completed: false,
    entity: { connect: { id: entityId } },
    drivingData: {
      create: {
        ownedVehicles: { create: {} },
        rentalVehicles: { create: {} },
        taxi: { create: {} },
        machineryAndEquipment: { create: {} },
      },
    },
    energy: { create: {} },
    waste: {
      create: {},
    },
    flights: {
      create: {
        icao: { create: {} },
        domesticFlights: { create: {} },
        internationalFlights: { create: {} },
      },
    },
    carbonOffset: { create: {} },
    entityMetadata: { create: {} },
  };
}

type DataModalProps = {
  modal: DialogStateReturn;
  chosenYear?: {
    entityId: FindOrCreateCarbonFootprintVariables['where']['id'];
    year: FindOrCreateCarbonFootprintVariables['create']['year'];
  };
  onSubmit?: (footprintId: number) => void;
};

export function DataModal({ modal, chosenYear, onSubmit }: DataModalProps) {
  const activeItem = useStore((state) => state.activeItem);
  const activeCategory = useStore((state) => state.activeCategory);
  const showOverview = useStore((state) => state.showOverview);
  const { setActiveCategory } = useStore((state) => state.actions);
  const categories = useStore((state) => state.categories);
  const { items } = categories.find((cat) => cat.key === activeCategory);

  const index = categories.findIndex((cat) => cat.key === activeCategory);
  const nextCategory = categories[index + 1]?.key;
  const prevCategory = categories[index - 1]?.key;

  const item = items.find((item) => item.key === activeItem);
  const nextItem = item.nextItem;

  const [selectedYear, setSelectedYear] = useState<number>(chosenYear?.year);
  const { role } = useUser();

  const [state, setState] =
    useState<FindOrCreateCarbonFootprint_upsertOneCarbonFootprint>();

  const [commit, footprint] = useMutation<
    FindOrCreateCarbonFootprint,
    FindOrCreateCarbonFootprintVariables
  >(FIND_OR_CREATE_CARBON_FOOTPRING);

  const [verify] = useMutation(VERIFY_CARBON_FOOTPRINT);

  const newSelectedYear = footprint?.data?.upsertOneCarbonFootprint.year;
  useEffect(() => {
    if (newSelectedYear) setSelectedYear(newSelectedYear);
  }, [newSelectedYear]);

  const { data, loading: queryLoading } = useQuery<
    FilteredGlobalCoefficients,
    FilteredGlobalCoefficientsVariables
  >(FILTERED_GLOBAL_COEFFICIENTS_QUERY, {
    variables: {
      where: {
        year: {
          equals: selectedYear,
        },
      },
    },
    skip: typeof selectedYear !== 'number',
  });

  const handleChooseFootprint = useCallback(
    ({ year, entityId }: { year: number; entityId: number }) =>
      commit({
        variables: {
          create: emptyCarbonFootprint(year, entityId),
          update: {},
          where: { entityId_year: { entityId: entityId, year } },
        },
      }).then(({ data }) => setState(data.upsertOneCarbonFootprint)),
    [commit],
  );

  useEffect(() => {
    if (chosenYear)
      handleChooseFootprint({
        entityId: chosenYear.entityId,
        year: chosenYear.year,
      });
  }, [chosenYear, handleChooseFootprint]);

  const [shouldSaveStateOnHidingModal, setShouldSaveStateOnHidingModal] =
    useState(true);

  const submitCarbonFootprint = useCallback(
    async (isCompleted: boolean = false) => {
      try {
        const { data } = await commit({
          variables: {
            // Should never create, only update
            create: emptyCarbonFootprint(state.year, state.entity.id),
            update: {
              ...parseStateIntoUpdateObject(state),
              completed: { set: isCompleted },
            },
            where: {
              entityId_year: { entityId: state.entity.id, year: state.year },
            },
          },
        });
        if (isCompleted) onSubmit?.(data.upsertOneCarbonFootprint.id);
      } catch (error) {
        console.error(error);
      }
      if (isCompleted) {
        setShouldSaveStateOnHidingModal(false);
        modal.hide();
      }
    },
    [commit, modal, onSubmit, state],
  );

  useEffect(() => {
    // Check if modal is closing
    if (shouldSaveStateOnHidingModal && !modal.visible && state) {
      commit({
        variables: {
          // Should never create, only update
          create: emptyCarbonFootprint(state.year, state.entity.id),
          update: {
            ...parseStateIntoUpdateObject(state),
          },
          where: {
            entityId_year: { entityId: state.entity.id, year: state.year },
          },
        },
      }).then(() => {
        setShouldSaveStateOnHidingModal(false);
        // reset modal navigation
        setActiveCategory('metadata');
        // Wait for hiding animation to finish
        setTimeout(() => setState(undefined), 200);
      });
    }
    if (modal.visible) setShouldSaveStateOnHidingModal(true);
  }, [
    commit,
    modal.visible,
    setActiveCategory,
    shouldSaveStateOnHidingModal,
    state,
  ]);

  const CategoryComponent = item.component;

  const renderBackButtons = useMemo(() => {
    if (prevCategory) {
      return (
        <Button variant="pill" onClick={() => setActiveCategory(prevCategory)}>
          Til baka í fyrri flokk
        </Button>
      );
    }
  }, [prevCategory, setActiveCategory]);

  const renderSaveAndClose = useMemo(() => {
    if (!state) {
      return;
    }

    if (nextItem || nextCategory) {
      return (
        <Button
          variant="secondary"
          onClick={() => {
            submitCarbonFootprint(true);
            modal.hide();
          }}
        >
          Vista og loka glugga
        </Button>
      );
    }
  }, [state, nextItem, nextCategory, modal, submitCarbonFootprint]);

  const renderForwardButtons = useMemo(() => {
    if (!state) {
      return;
    }

    if (showOverview) {
      return (
        <Button variant="primary" onClick={() => submitCarbonFootprint(true)}>
          Staðfesta
        </Button>
      );
    }

    if (nextItem) {
      return (
        <Button
          variant="primary"
          onClick={() => {
            useStore.setState({ activeItem: nextItem });
            submitCarbonFootprint(false);
          }}
        >
          Vista og halda áfram
        </Button>
      );
    }

    if (nextCategory) {
      return (
        <Button
          variant="primary"
          onClick={() => {
            setActiveCategory(nextCategory);
            submitCarbonFootprint(false);
          }}
        >
          Vista og fara í næsta flokk
        </Button>
      );
    }

    return (
      <Button
        variant="primary"
        onClick={() => useStore.setState({ showOverview: true })}
      >
        Vista og sjá yfirlit
      </Button>
    );
  }, [
    state,
    nextItem,
    nextCategory,
    showOverview,
    setActiveCategory,
    submitCarbonFootprint,
  ]);

  const coefficients = data?.globalCoefficients[0];
  const scrollable = useRef<HTMLDivElement>(null);

  const renderScreen = useMemo(() => {
    if (showOverview) {
      return <Overview state={state} globalCoefficients={coefficients} />;
    }

    if (state) {
      return (
        <CategoryComponent
          state={state}
          onChange={setState}
          globalCoefficients={coefficients}
        />
      );
    } else {
      return (
        <establishment.Establishment
          onClose={() => modal.hide()}
          onChoose={handleChooseFootprint}
        />
      );
    }
  }, [
    showOverview,
    state,
    coefficients,
    CategoryComponent,
    handleChooseFootprint,
    modal,
  ]);

  const renderLoadingScreen = useMemo(() => {
    return (
      <Box
        $display="flex"
        $flexDirection="column"
        $minHeight="100%"
        $alignItems="center"
        $justifyContent="center"
      >
        <LabelText>Sæki gögn...</LabelText>
      </Box>
    );
  }, []);

  useEffect(() => {
    if (scrollable.current && item) {
      scrollable.current.scrollTo(0, 0);
    }
  }, [scrollable, item]);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!queryLoading) {
      waitFor(500).then(() => {
        setLoading(false);
      });
    }
  }, [queryLoading]);

  const markVerified = (isVerified: boolean) => async () => {
    await verify({
      variables: {
        id: state.id,
        isVerified,
      },
    });
    setState({
      ...state,
      isVerified,
    });
  }

  const showSidebar = state && !showOverview;

  return (
    <layout.modalOuter>
      <layout.inner>
        {showSidebar ? (
          <ModalSidebar
            selectedYear={state.year}
            activeEstablishmentName={state.entity.name}
          >
            {role === 'ADMIN' &&
              (state.isVerified ? (
                <>
                  <p>Merkt yfirfarið<br/><span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={markVerified(false)}
                  >
                    Taka til baka
                  </span></p>

                </>
              ) : (
                <Button
                  onClick={markVerified(true)}
                >
                  Merkja yfirfarið
                </Button>
              ))}
          </ModalSidebar>
        ) : null}
        <layout.content>
          <layout.scrollable ref={scrollable}>
            {loading ? renderLoadingScreen : renderScreen}
          </layout.scrollable>
          {(prevCategory || state) && (
            <layout.bottom>
              <Box $paddingRight={2} $flex="1">
                {renderBackButtons}
              </Box>
              <Box
                $display="flex"
                $justifyContent="flex-end"
                $width="full"
                $flex="1"
              >
                <Box $pr={1} $pl={1}>
                  {renderSaveAndClose}
                </Box>
                <Box $paddingLeft={2}>{renderForwardButtons}</Box>
              </Box>
            </layout.bottom>
          )}
        </layout.content>
      </layout.inner>
    </layout.modalOuter>
  );
}
