import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from 'styled-components';
import { ApolloProvider } from '@apollo/client/react';
import './index.css';
import './recharts-styles.css';
import './datepicker.css';
import App from './App';
import theme from './utils/theme';
import { client } from './utils/apolloClient';

ReactDOM.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <Suspense fallback={null}>
          <App />
        </Suspense>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
