import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';
import { H2, H3, Text } from '../../components/Text';
import Button from '../../components/Button/Button';
import Select from '../../components/Select';
import { Box } from '../../styles/Box';
import { useStore } from '../../state';
import theme from '../../utils/theme';
import { GET_ENTITIES_IN_MUNICIPALITY } from '../../queries/getEntitiesInMunicipality';
import {
  GetEntitiesInMunicipality,
  GetEntitiesInMunicipalityVariables,
} from '../../queries/__generated__/GetEntitiesInMunicipality';
import { GridContainer, GridItem } from '../../styles/Grid';
import { useUser } from '../../context/user';
import yearRange from '../../utils/yearRange';

const styles = {
  wrap: styled('div')({
    display: 'flex',
    alignItems: 'center',
  }),
  status: styled('div')<{ status: 'ok' | 'in-progress' }>(({ status }) => ({
    background:
      status === 'ok' ? theme.colors.green100 : theme.colors.yellow100,
    width: 8,
    height: 8,
    borderRadius: '50%',
    marginLeft: theme.space[1.5],
  })),
};

const Establishment = ({
  onChoose,
  onClose,
}: {
  onChoose: ({
    year,
    entityId,
    carbonFootprintId,
  }: {
    year: number;
    entityId: number;
    carbonFootprintId?: number;
  }) => Promise<any>;
  onClose?: () => void;
}) => {
  const { setActiveCategory } = useStore((state) => state.actions);
  const categories = useStore((state) => state.categories);
  const { municipality } = useUser();
  const currentYear = new Date().getFullYear();
  const years = [...yearRange(currentYear, 2010)];

  const { data } = useQuery<
    GetEntitiesInMunicipality,
    GetEntitiesInMunicipalityVariables
  >(GET_ENTITIES_IN_MUNICIPALITY, {
    variables: { where: { id: municipality.id } },
  });

  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedYear, setSelectedYear] = useState(currentYear);

  useEffect(() => {
    if (data && selectedEntity === null) {
      setSelectedEntity(data.municipality.entities[0]);
    }
  }, [data, selectedEntity]);

  if (!data) return null;

  const onContinue = async () => {
    if (!selectedEntity) {
      return;
    }

    const carbonFootprint = selectedEntity.carbonFootprintByYear.find(
      (carbonFootprint) => carbonFootprint.year === selectedYear,
    );

    await onChoose({
      year: selectedYear,
      entityId: selectedEntity?.id,
      carbonFootprintId: carbonFootprint?.id, //TODO: This can fail
    });

    useStore.setState({
      activeEstablishment: selectedEntity.name,
      selectedYear: selectedYear,
    });

    setActiveCategory(categories[0].key);
  };

  const setEntityById = (id: number) => {
    const entity = data.municipality.entities.find(
      (entity) => entity.id === id,
    );
    if (entity) {
      setSelectedEntity(entity);
    }
  };

  const getEntityStatus = (entity) =>
    entity &&
    entity.carbonFootprintByYear.some(
      (carbonFootprint) =>
        carbonFootprint.year === selectedYear && carbonFootprint.completed,
    )
      ? 'ok'
      : 'in-progress';

  return (
    <Box $display="flex" $flexDirection="column" $height="full">
      <Box $paddingTop={4} $paddingBottom={1}>
        <H2>Skrá gögn fyrir rekstrareiningu</H2>
        <H3 paddingY={2} color="black80" fontWeight={400}>
          Veldu rekstrareiningu og tímabil.
        </H3>
      </Box>
      <GridContainer $gridTemplateColumns="repeat(6, 1fr)" $paddingY={2}>
        <GridItem $gridColumn="span 3">
          <Select
            title="Rekstrareining"
            options={data.municipality.entities.map((entity) => ({
              label: entity.name,
              value: entity.id,
            }))}
            onChange={(entity) => setEntityById(Number(entity.value))}
          />
        </GridItem>
        <GridItem $gridColumn="span 3">
          <Select
            title="Tímabil"
            options={years.map((year) => ({
              label: `${year}`,
              value: year,
            }))}
            onChange={(year) => setSelectedYear(Number(year.value))}
            renderLabel={(label) => (
              <styles.wrap>
                <Text>{label}</Text>{' '}
                <styles.status status={getEntityStatus(selectedEntity)} />
              </styles.wrap>
            )}
          />
        </GridItem>
      </GridContainer>
      <GridContainer $paddingY={4} $mt="auto">
        <GridItem $gridColumn="3 / span 1">
          <Button variant="secondary" onClick={onClose} fillWidth>
            Loka glugga
          </Button>
        </GridItem>
        <GridItem $gridColumn="4 / span 1">
          <Button variant="primary" onClick={onContinue} fillWidth>
            Áfram
          </Button>
        </GridItem>
      </GridContainer>
    </Box>
  );
};

export const establishment = {
  Establishment,
};
